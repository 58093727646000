import React, { useEffect, useState } from "react";
import LoaderComponent from "../../Loader/Loader";
import SectionHeadingStyle3 from "../../../components/SectionHeading/SectionHeadingStyle3";
import Spacing from "../../../components/Spacing/index";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../context/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../context/firebase";
import toast from "react-hot-toast";
import { useTranslation } from "../../../hooks/useTranslation";

const MyWithdraws = () => {
  const { translate } = useTranslation();
  const [withdraws, setWithdraws] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWithdraws = async () => {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        "https://getuserwithdraw-qj6yfdqbiq-uc.a.run.app",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error(translate("myWithdraws.fetchError"));
        return;
      }

      const data = await response.json();
      const sortedWithdraws = data.withdrawals.sort(
        (a, b) => b.timestamp._seconds - a.timestamp._seconds
      );
      setWithdraws(sortedWithdraws);
      setLoading(false);
    };

    fetchWithdraws();
  }, []);

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("myWithdraws.title")}
        subTitle=" "
        variant="text-left"
        href="/transactions"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th style={{ width: "16.66%", textAlign: "center" }}>{translate("myWithdraws.id")}</th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      {translate("myWithdraws.date")}
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      {translate("myWithdraws.time")}
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      {translate("myWithdraws.amount")}
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      {translate("myWithdraws.status")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : withdraws.length === 0 ? (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: "center",
                          padding: "50px 0",
                          fontSize: "1.2rem",
                          color: "#666"
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "450px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h3>{translate("myWithdraws.noWithdraws")}</h3>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    withdraws.map((withdraw, index) => (
                      <tr
                        key={withdraw.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td style={{ textAlign: "center" }}>
                          {withdraw.id.length > 8
                            ? `${withdraw.id.slice(0, 4)}...${withdraw.id.slice(
                                -4
                              )}`
                            : withdraw.id}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Date(
                            withdraw.timestamp._seconds * 1000
                          ).toLocaleDateString("pt-BR")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Date(
                            withdraw.timestamp._seconds * 1000
                          ).toLocaleTimeString("pt-BR")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(withdraw.amount)}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color:
                              withdraw.status === "confirmed"
                                ? "#00FF00"
                                : withdraw.status === "pending"
                                ? "#14FFFF"
                                : "inherit",
                          }}
                        >
                          {withdraw.status}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWithdraws;
