import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';

export function useTranslation() {
  const { language, setLanguage, translations } = useContext(LanguageContext);

  const translate = (key) => {
    return translations[key] || key;
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return {
    translate,
    language,
    changeLanguage
  };
} 