import React, { useState, useRef, useEffect } from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../../helpers/PageTitle";
import { Input } from "../Profile";
import styled from "styled-components";
import toast from "react-hot-toast";
import LoaderComponent from "../../Loader/Loader";
import { useNumericInput } from "../../../hooks/useNumericInput";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../context/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks/useTranslation";

const StyledInput = styled(Input)`
  &:focus {
    background-color: #1a1a1a !important;
    border-color: #d4af37 !important;
    box-shadow: 0 0 0 0.25rem rgba(212, 175, 55, 0.25) !important;
    color: #fff !important;
  }

  &::placeholder {
    color: #888 !important;
    opacity: 1;
  }
`;

const StyledTextarea = styled.textarea`
  background-color: #0a0a0a;
  border: 1px solid #c8af86;

  &:focus {
    background-color: #1a1a1a !important;
    border-color: #d4af37 !important;
    box-shadow: 0 0 0 0.25rem rgba(212, 175, 55, 0.25) !important;
    color: #fff !important;
  }

  &::placeholder {
    color: #888 !important;
    opacity: 1;
  }
`;

export default function EditProject() {
  pageTitle("Edit Project");
  const location = useLocation();
  const { projectId, title, description, descriptionEN, startDate, endDate, videoUrl } =
    location.state || {};

  const { translate } = useTranslation();

  const [formData, setFormData] = useState({
    title: title || "",
    description: description || "",
    descriptionEN: descriptionEN || "",
    startDate: startDate || "",
    endDate: endDate || "",
    videoUrl: videoUrl || "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();


  const [selectedFiles, setSelectedFiles] = useState({
    image: null,
    thumbnail: null,
    dueDiligence: null,
    prospectus: null,
    presentation: null,
    fullDescription: null,
    fullDescriptionEN: null,
  });

  const fileTypes = {
    imageUrl: "image",
    thumbnailUrl: "thumbnail",
    dueDiligenceUrl: "dueDiligence",
    prospectusUrl: "prospectus",
    presentationUrl: "presentation",
    fullDescriptionUrl: "fullDescription",
    fullDescriptionUrlEN: "fullDescriptionEN",
  };

  const handleFileUpload = async (file, fileType) => {
    try {
      if (!file) return null;

      const contentTypeMap = {
        md: "text/markdown",
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        image: "image/*",
      };

      const extension = file.name.split(".").pop().toLowerCase();
      const contentType = contentTypeMap[extension] || file.type;

      const storageRef = ref(storage, `projects/${projectId}/${fileType}`);

      const metadata = {
        contentType: contentType,
      };

      await uploadBytes(storageRef, file, metadata);
      const downloadURL = await getDownloadURL(storageRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const fileType = name.replace("Url", "");
      setSelectedFiles((prev) => {
        const newState = {
          ...prev,
          [fileType]: files[0],
        };
        return newState;
      });
    } else {
      if (name === "sharePrice") {
        // Permite números e ponto decimal
        const numericValue = value.replace(/[^\d.]/g, "");
        if (numericValue.split(".").length > 2) return;

        setFormData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      } else if (name === "totalShares" || name === "reservedShares") {
        // Permite apenas números inteiros
        const integerValue = value.replace(/\D/g, "");

        setFormData((prev) => ({
          ...prev,
          [name]: integerValue,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Invalid session");
        throw new Error("Invalid session");
      }

      // Upload files first and collect URLs
      const uploadedUrls = {
        imageUrl: "",
        thumbnailUrl: "",
        dueDiligenceUrl: "",
        prospectusUrl: "",
        presentationUrl: "",
        fullDescriptionUrl: "",
        fullDescriptionUrlEN: "",
      };

      for (const [fieldName, fileType] of Object.entries(fileTypes)) {
        if (selectedFiles[fileType]) {
          const url = await handleFileUpload(selectedFiles[fileType], fileType);
          if (url) {
            uploadedUrls[fieldName] = url;
          }
        }
      }

      // Prepare complete form data
      const completeFormData = {
        projectId,
        title: formData.title.trim(),
        description: formData.description.trim(),
        descriptionEN: formData.descriptionEN.trim(),
        startDate: formData.startDate,
        endDate: formData.endDate,
        videoUrl: formData.videoUrl,
        ...uploadedUrls,
      };
      console.log("🚀 ~ handleSubmit ~ completeFormData:", completeFormData)
      // Single API call to update everything
      const response = await fetch(
        "https://updatecrowdfundproject-qj6yfdqbiq-uc.a.run.app",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(completeFormData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error updating project:", errorText);
        if (errorText.includes("You are not the creator of this project")) {
          throw new Error("You are not the creator of this project");
        }
        throw new Error("Error updating project");
      }

      toast.success("Project updated successfully!");
      setSuccess("Project updated successfully!");
      setFormData({
        title: "",
        description: "",
        descriptionEN: "",
        startDate: "",
        endDate: "",
        videoUrl: "",
      });
      setSelectedFiles({});
      navigate("/projects");
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateGoalAmount = () => {
    if (formData.sharePrice && formData.totalShares) {
      return Number(formData.sharePrice) * Number(formData.totalShares);
    }
    return 0;
  };

  return (
    <>
      {loading && <LoaderComponent fullPage={true} />}
      <Spacing lg="90" md="90" />
      <section>
        <div className="container">
          <SectionHeadingStyle3
            title="Edit Project"
            subTitle="Project"
            shape="shape_5"
          />
          <div className="row">
            <div className="col-md-12">
              <div
                style={{ background: "#222221" }}
                className="p-4 text-light rounded"
              >
                {error && <div className="alert alert-danger">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="flex flex-column">
                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.title")}</label>
                      <StyledInput
                        placeholder={translate("editProject.titlePlaceholder")}
                        type="text"
                        name="title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.shortDescription")}</label>
                      <StyledTextarea
                        placeholder={translate("editProject.shortDescription")}
                        name="description"
                        className="form-control"
                        rows="4"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.shortDescriptionEN")}</label>
                      <StyledTextarea
                        placeholder={translate("editProject.shortDescriptionEN")}
                        name="descriptionEN"
                        className="form-control"
                        rows="4"
                        value={formData.descriptionEN}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.startDate")}</label>
                      <StyledInput
                        type="date"
                        name="startDate"
                        className="form-control"
                        value={formData.startDate}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.endDate")}</label>
                      <StyledInput
                        type="date"
                        name="endDate"
                        className="form-control"
                        value={formData.endDate}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.videoUrl")}</label>
                      <StyledInput
                        type="text"
                        name="videoUrl"
                        className="form-control"
                        value={formData.videoUrl}
                        onChange={handleChange}
                        placeholder={translate("editProject.videoUrlPlaceholder")}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.projectImage")}</label>
                      <StyledInput
                        style={{ color: "#888", paddingLeft: "1.5rem" }}
                        type="file"
                        name="imageUrl"
                        className="form-control"
                        onChange={handleChange}
                        accept="image/*"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{translate("editProject.thumbnailImage")}</label>
                      <StyledInput
                        style={{ color: "#888", paddingLeft: "1.5rem" }}
                        type="file"
                        name="thumbnailUrl"
                        className="form-control"
                        onChange={handleChange}
                        accept="image/*"
                      />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">{translate("createProject.fullDescription")}</label>
                        <StyledInput
                          style={{ color: "#888", paddingLeft: "1.5rem" }}
                          type="file"
                          name="fullDescriptionUrl"
                          className="form-control"
                          onChange={handleChange}
                          accept=".md"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">{translate("createProject.fullDescriptionEN")}</label>
                        <StyledInput
                          style={{ color: "#888", paddingLeft: "1.5rem" }}
                          type="file"
                          name="fullDescriptionUrlEN"
                          className="form-control"
                          onChange={handleChange}
                          accept=".md"
                        />
                      </div>

                    <div className="mb-3">
                      <label className="form-label">
                        {translate("editProject.dueDiligence")}
                      </label>
                      <StyledInput
                        style={{ color: "#888", paddingLeft: "1.5rem" }}
                        type="file"
                        name="dueDiligenceUrl"
                        className="form-control"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">
                        {translate("editProject.prospectus")}
                      </label>
                      <StyledInput
                        style={{ color: "#888", paddingLeft: "1.5rem" }}
                        type="file"
                        name="prospectusUrl"
                        className="form-control"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">
                        {translate("editProject.presentation")}
                      </label>
                      <StyledInput
                        style={{ color: "#888", paddingLeft: "1.5rem" }}
                        type="file"
                        name="presentationUrl"
                        className="form-control"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx"
                      />
                    </div>

                    <button
                      disabled={loading}
                      type="submit"
                      className="cs_btn cs_style_1"
                    >
                      {loading ? translate("editProject.editing") : translate("editProject.editProject")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
