export default {
  welcome: "Bem-vindo",
  language: "Idioma",
  "home.pageTitle": "Plataforma de Investimentos Tokenizados",
  "home.hero.miniTitle": "Plataforma de Investimentos Tokenizados",
  "home.hero.title": "Faça Grandes Investimentos",
  "home.hero.subTitle":
    "Transforme seu potencial de investimento com nossa plataforma tokenizada - acesse propriedade fracionada em startups premium, imóveis e fundos de VC anteriormente reservados para investidores institucionais.",

  "home.projects.title": "Projetos",
  "home.projects.subTitle": "Nossos",
  "home.projects.viewAll": "Ver todos",
  "home.projects.items.startups.title": "Startups",
  "home.projects.items.startups.subTitle":
    "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
  "home.projects.items.startups.btnText": "Investir",
  "home.projects.items.energy.title": "Energia",
  "home.projects.items.energy.subTitle":
    "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
  "home.projects.items.energy.btnText": "Investir",
  "home.projects.items.realState.title": "Real State",
  "home.projects.items.realState.subTitle":
    "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
  "home.projects.items.realState.btnText": "Investir",
  "home.projects.items.bigData.title": "Big Data Inc.",
  "home.projects.items.bigData.subTitle":
    "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
  "home.projects.items.bigData.btnText": "Investir",

  "home.about.title": "Investimento Simplificado",
  "home.about.subTitle":
    "Desbloqueie oportunidades de investimento premium. Nossa plataforma tokenizada permite que você possua ações em ativos de alto potencial com o valor acessivel. Sem burocracia, zero complicação - apenas potencial de investimento puro com total transparência e alta liquidez.",
  "home.about.features.0": "Oportunidades de investimento premium",
  "home.about.features.1": "Sem burocracia e complicações",
  "home.about.features.2": "Operação totalmente tokenizada",
  "home.about.features.3": "Retorno acima do mercado",
  "home.about.btnText": "Invista agora",

  "home.funFacts.invested.title": "Investidos",
  "home.funFacts.invested.number": "R$72M",
  "home.funFacts.projects.title": "Projetos Lançados",
  "home.funFacts.projects.number": "65",
  "home.funFacts.investors.title": "Investidores",
  "home.funFacts.investors.number": "1k",
  "home.funFacts.return.title": "Retorno Anual",
  "home.funFacts.return.number": "+23,96%",

  "home.video.title": "Descubra novas oportunidades",

  "home.testimonials.items.0.text":
    "Estou impressionado com os retornos da Barzel - meus investimentos têm rendido consistentemente 1,99% ao mês. A plataforma facilita o acompanhamento do desempenho e o reinvestimento dos lucros.",
  "home.testimonials.items.0.avatarName": "Daniel Goldfarb",
  "home.testimonials.items.0.avatarDesignation": "Cliente Barzel",
  "home.testimonials.items.1.text":
    "A Barzel é fantástica! Plataforma simples e transparente com ótimo suporte. Consegui financiar meu projeto facilmente.",
  "home.testimonials.items.1.avatarName": "Gustavo Albuquerque",
  "home.testimonials.items.1.avatarDesignation": "CEO da Bosforo",
  "home.testimonials.items.2.text":
    "A Barzel supera as expectativas! Sua plataforma direta e transparente e suporte excepcional tornaram o financiamento do meu projeto muito fácil. Não posso recomendar o suficiente!",
  "home.testimonials.items.2.avatarName": "Avraham Goldstein",
  "home.testimonials.items.2.avatarDesignation": "CEO da Delta",

  "home.awards.title": "Por que investir?",
  "home.awards.subTitle": "Descubra",
  "home.awards.items.0.title": "Design UI/UX do mês",
  "home.awards.items.0.subTitle":
    "Investimentos através da plataforma trazem alguns dos retornos mais rápidos do segmento.",
  "home.awards.items.0.date": "12 de Dezembro de 2023",
  "home.awards.items.1.title": "Prêmio de design CSS",
  "home.awards.items.1.subTitle":
    "Apoie o empreendedorismo, gere empregos e impulsione a economia.",
  "home.awards.items.1.date": "05 de Janeiro de 2022",
  "home.awards.items.2.title": "Site do dia",
  "home.awards.items.2.subTitle":
    "Conecte-se com nossa comunidade de investidores e empreendedores.",
  "home.awards.items.2.date": "20 de Março de 2021",

  "home.blog.title": "Benefícios de Investir conosco",
  "home.blog.subTitle": "Leia mais",
  "home.blog.posts.0.title":
    "Como evitar que o medo arruíne seu negócio artístico com confiança",
  "home.blog.posts.0.date": "07 Mar 2023",
  "home.blog.posts.1.title":
    "Mente artística será ótima para criar qualquer coisa",
  "home.blog.posts.1.date": "22 Abr 2023",
  "home.blog.posts.2.title":
    "IA assumirá todos os empregos humanos em poucos anos",
  "home.blog.posts.2.date": "13 Mai 2023",
  "home.blog.posts.3.title":
    "Sua agência precisa substituir algumas pessoas com mente artística",
  "home.blog.posts.3.date": "15 Mar 2023",

  "home.faq.title": "Perguntas Frequentes",
  "home.faq.subTitle": "FAQ",
  "home.faq.items.0.title": "01. Qual é o modelo legal de investimento?",
  "home.faq.items.0.content":
    "De acordo com a lei brasileira CVM 88, nossa plataforma de investimento opera sob o modelo de crowdfunding para investimento em startups e pequenas empresas. Esta regulamentação permite a oferta pública de valores mobiliários por pequenas empresas através de plataformas eletrônicas, fornecendo uma estrutura legal para investidores de varejo participarem de investimentos em estágio inicial.",
  "home.faq.items.1.title":
    "02. Quais são os riscos de investir através da Barzel?",
  "home.faq.items.1.content":
    "Como em qualquer investimento, existem riscos envolvidos. Investir em startups e pequenas empresas através de nossa plataforma carrega um alto risco de perda parcial ou total do capital. Os valores mobiliários oferecidos não são garantidos pelo Fundo Garantidor de Crédito (FGC) ou qualquer outro mecanismo de seguro. É importante diversificar seus investimentos e investir apenas o dinheiro que você pode se dar ao luxo de perder.",
  "home.faq.items.2.title":
    "03. Quais são as vantagens de investir através da Barzel?",
  "home.faq.items.2.content":
    "Investir através da Barzel oferece várias vantagens: acesso a uma seleção curada de startups e pequenas empresas, capacidade de investir com valores menores em comparação com o capital de risco tradicional, potencial de altos retornos, diversificação de portfólio e oportunidade de apoiar empresas inovadoras. Nossa plataforma também fornece transparência e informações detalhadas sobre cada oportunidade de investimento, conforme exigido pela CVM 88.",
  "home.faq.items.3.title":
    "04. O processo de investimento é totalmente online?",
  "home.faq.items.3.content":
    "Sim, todo o processo de investimento é realizado online através de nossa plataforma. Isso inclui navegar por oportunidades de investimento, realizar due diligence, tomar decisões de investimento e gerenciar seu portfólio. No entanto, conforme as regulamentações da CVM 88, certos documentos e confirmações podem precisar ser fornecidos eletronicamente para cumprir os requisitos de verificação do investidor.",
  "home.faq.items.4.title": "05. Eu me tornarei acionista da empresa?",
  "home.faq.items.4.content":
    "O tipo de título que você recebe depende da oferta específica. Em muitos casos, você receberá notas conversíveis ou outras formas de títulos que podem ser convertidos em ações sob certas condições. Algumas ofertas podem fornecer participação direta. É crucial ler atentamente os termos de cada oportunidade de investimento. Lembre-se que, conforme a CVM 88, seus direitos como investidor, incluindo direitos de voto e direitos econômicos, serão claramente descritos nos documentos da oferta.",

  "home.cta.title":
    "Pronto para desbloquear o futuro dos investimentos através da tokenização?",
  "home.cta.btnText": "Contate-nos",

  "home.hero.btnText": "Comece a Investir",

  // Header translations
  "header.menu.home": "Início",
  "header.menu.profile": "Perfil",
  "header.menu.newProject": "Novo Projeto",
  "header.menu.manageWithdraws": "Gerir Saques",
  "header.menu.manageDeposits": "Gerir Depósitos",
  "header.menu.manageCrypto": "Gerir Crypto",
  "header.menu.balance": "Saldo",
  "header.menu.deposit": "Depositar",
  "header.menu.deposits": "Depósitos",
  "header.menu.withdraw": "Sacar",
  "header.menu.withdraws": "Saques",
  "header.menu.transactions": "Transações",
  "header.menu.portfolio": "Portfólio",
  "header.menu.market": "Mercado",
  "header.menu.projects": "Projetos",
  "header.menu.logout": "Sair",
  "header.auth.login": "Entrar",
  "header.auth.register": "Cadastrar",

  "header.balance.details": "Ver Detalhes",
  "header.balance.brl": "BRL",
  "header.balance.btc": "BTC",
  "header.balance.usdt": "USDT",
  "header.balance.invested": "Investido",
  "header.balance.pending": "Pendente",

  // Language selector translations
  "language.selector.portuguese": "🇧🇷 Português",
  "language.selector.english": "🇺🇸 English",

  // Projects
  "home.serviceSlider.IconBox.buttonLink": "Investir",

  // Footer translations
  "footer.address": "Av. Brg. Faria Lima, 3477 - Itaim Bibi",
  "footer.phone": "+55 82 99635 0992",
  "footer.email": "contact@barzel.com",
  "footer.services.title": "Serviços",
  "footer.links.title": "Links",
  "footer.newsletter.title": "Assine Nossa Newsletter",
  "footer.newsletter.text":
    "Garantimos enviar apenas e-mails relevantes e dignos de nota para você.",
  "footer.newsletter.placeholder": "Endereço de e-mail",
  "footer.newsletter.button": "Enviar",
  "footer.copyright": "Direitos Autorais © 2024 Barzel.",
  "footer.policy.terms": "Termos de Uso",
  "footer.policy.privacy": "Política de Privacidade",

  // Footer links translations
  "footer.links.home": "Início",
  "footer.links.about": "Sobre",
  "footer.links.contact": "Contato",
  "footer.links.terms-and-conditions": "Termos e Condições",

  // Footer service menu translations
  "footer.services.internationalInvestments": "Investimentos Internacionais",
  "footer.services.tokenizationRWA": "Tokenização de RWA",
  "footer.services.bitcoinOTC": "Bitcoin OTC",

  // Marketplace translations
  "marketplace.title": "Mercado",
  "marketplace.subTitle": "Loja",

  // UserWallet translations
  "userWallet.title": "Suas Ações",
  "userWallet.empty": "Você ainda não possui ações em seu portfólio.",
  "userWallet.sellButton": "VENDER AÇÃO",
  "userWallet.originalValue": "Valor Original",
  "userWallet.totalValue": "Total Avaliado",
  "userWallet.project": "Projeto",
  "userWallet.shares": "Ações",
  "userWallet.action": "Ação",

  // Offers translations
  "offers.title": "Livro de Ofertas de Ações",
  "offers.empty": "Não há ofertas disponíveis no momento.",
  "offers.project": "Projeto",
  "offers.shares": "Ações",
  "offers.pricePerShare": "Preço por Ação",
  "offers.total": "Total",
  "offers.cancel": "CANCELAR",
  "offers.buy": "COMPRAR",
  "offers.previous": "Anterior",
  "offers.next": "Próxima",
  "offers.page": "Página",
  of: "de",

  // Projects translations
  "projects.title": "Projetos",
  "projects.subtitle": "Nossos",
  "projects.previous": "Anterior",
  "projects.next": "Próxima",
  "projects.page": "Página",

  // Profile translations
  "profile.title": "Meu Perfil",
  "profile.subtitle": "Perfil",
  "profile.sessionExpired": "Sessão expirada, por favor faça login novamente.",
  "profile.invalidSession": "Sessão inválida, por favor faça login novamente.",
  "profile.avatarChange": "Alterar Avatar",
  "profile.name": "Nome",
  "profile.cpf": "CPF",
  "profile.birthdate": "Data de Nascimento",
  "profile.gender": "Gênero",
  "profile.selectGender": "Selecione o gênero",
  "profile.male": "Masculino",
  "profile.female": "Feminino",
  "profile.other": "Outro",
  "profile.nationality": "Nacionalidade",
  "profile.address": "Endereço",
  "profile.phone": "Telefone",
  "profile.income": "Renda Anual",
  "profile.consultant": "Consultor",
  "profile.saveChanges": "Salvar Alterações",
  "profile.edit": "Editar",
  "profile.uploadAvatarError": "Erro ao fazer upload do avatar",
  "profile.processImageError": "Erro ao processar imagem",
  "profile.fetchProfileError": "Erro ao carregar dados do perfil",
  "profile.updateProfileError": "Erro ao atualizar perfil",
  "profile.profileUpdated": "Perfil atualizado com sucesso!",
  "profile.cropImageSuccess": "Clique em 'Salvar Alterações' para confirmar.",
  "profile.updateProfileSuccess": "Perfil atualizado com sucesso!",
  "profile.updateProfileError": "Erro ao atualizar perfil",

  // Dashboard translations
  "dashboard.title": "Dashboard",
  "dashboard.subtitle": "Gerencie seus saldos",
  "dashboard.loading": "Carregando...",
  "dashboard.error": "Erro ao buscar ações:",
  "dashboard.currentBalance": "Saldo Atual",
  "dashboard.brlBalance": "Saldo BRL",
  "dashboard.btcBalance": "Saldo BTC",
  "dashboard.usdtBalance": "Saldo USDT",
  "dashboard.pendingBalance": "Saldo Pendente",
  "dashboard.totalInBRL": "Total em BRL",
  "dashboard.sharesBalance": "Saldo em Ações",
  "dashboard.sharesBRL": "Ações BRL",
  "dashboard.sharesBTC": "Ações BTC",
  "dashboard.sharesUSDT": "Ações USDT",
  "dashboard.pendingShares": "Ações Pendente",
  "dashboard.totalBRLInShares": "Total de BRL em Ações",
  "dashboard.buyBitcoin": "Comprar Bitcoin ",
  "dashboard.sellBitcoin": "Vender Bitcoin",

  // CreateProject translations

  "createProject.title": "Criar Projeto",
  "createProject.subtitle": "Novo Projeto",
  "createProject.projectTitle": "Título do Projeto",
  "createProject.success": "Arquivos adicionados com sucesso!",
  "createProject.error": "Erro ao atualizar projeto",
  "createProject.invalidSession": "Sessão inválida",
  "createProject.uploadError": "Erro ao fazer upload do arquivo",
  "createProject.fileNotSelected": "Nenhum arquivo selecionado para {fileType}",
  "createProject.urlNotObtained": "URL para {fileType} não foi obtida",
  "createProject.filesAdded": "Arquivos adicionados com sucesso!",
  "createProject.shortDescription": "Descrição Curta (PT-BR)",
  "createProject.descriptionEN": "Descrição Curta (EN-US)",
  "createProject.fullDescription": "Descrição Completa (PT-BR)",
  "createProject.fullDescriptionEN": "Descrição Completa (EN-US)",
  "createProject.endDate": "Data de Término",
  "createProject.videoUrl": "URL do Vídeo do Projeto (YouTube)",
  "createProject.files": "Arquivos",
  "createProject.presentation": "Documento de Apresentação",
  "createProject.currency": "Moeda",
  "createProject.type": "Tipo",
  "createProject.sharePrice": "Preço da Ação",
  "createProject.totalShares": "Total de Ações",
  "createProject.reservedShares": "Ações Reservadas",
  "createProject.goalAmount": "Valor do Objetivo",
  "createProject.startDate": "Data de Início",
  "createProject.finishProject": "Finalizar Projeto",
  "createProject.back": "Voltar",
  "createProject.dueDiligence": "Documento de Due Diligence",
  "createProject.prospectus": "Documento de Prospecto",
  "createProject.presentation": "Documento de Apresentação",
  "createProject.back": "Voltar",
  "createProject.finishProject": "Finalizar Projeto",
  "createProject.saveAndContinue": "Salvar e Continuar",
  "createProject.thumbnailImage": "Imagem em Miniatura",

  // DepositAdmin translations
  "depositAdmin.title": "Administração de Depósitos",
  "depositAdmin.subtitle": "administração",
  "depositAdmin.email": "Email",
  "depositAdmin.cpf": "CPF",
  "depositAdmin.date": "Data",
  "depositAdmin.amount": "Valor",
  "depositAdmin.receipt": "Comprovante",
  "depositAdmin.actions": "Ações",
  "depositAdmin.confirmSuccess": "Depósito confirmado com sucesso",
  "depositAdmin.rejectSuccess": "Depósito reprovado com sucesso",
  "depositAdmin.confirmError": "Erro ao confirmar o depósito",
  "depositAdmin.rejectError": "Erro ao reprovar o depósito",
  "depositAdmin.noReceipt": "Não há um comprovante para este depósito",
  "depositAdmin.previous": "Anterior",
  "depositAdmin.next": "Próxima",
  "depositAdmin.page": "Página",
  "depositAdmin.loadingTransactions": "Carregando transações...",

  // CryptoAdmin translations
  "cryptoAdmin.title": "Administração de Cryptos",
  "cryptoAdmin.subtitle": "administração",
  "cryptoAdmin.email": "Email",
  "cryptoAdmin.cpf": "CPF",
  "cryptoAdmin.date": "Data",
  "cryptoAdmin.amount": "Valor",
  "cryptoAdmin.type": "Tipo",
  "cryptoAdmin.receipt": "Comprovante",
  "cryptoAdmin.actions": "Ações",
  "cryptoAdmin.confirmSuccess": "Crypto confirmada com sucesso",
  "cryptoAdmin.rejectSuccess": "Crypto reprovada com sucesso",
  "cryptoAdmin.confirmError": "Erro ao confirmar a crypto",
  "cryptoAdmin.rejectError": "Erro ao reprovar a crypto",
  "cryptoAdmin.noReceipt": "Não há um comprovante para esta crypto",
  "cryptoAdmin.previous": "Anterior",
  "cryptoAdmin.next": "Próxima",
  "cryptoAdmin.page": "Página",
  "cryptoAdmin.loadingTransactions": "Carregando transações...",
  "cryptoAdmin.buy": "Compra",
  "cryptoAdmin.sell": "Venda",
  "crypto.Admin.button.purchase.confirm": "Confirmar Compra",
  "crypto.Admin.button.purchase.reject": "Rejeitar Compra",
  "crypto.Admin.button.sale.confirm": "Confirmar Venda",
  "crypto.Admin.button.sale.reject": "Rejeitar Venda",
  "crypto.Admin.button.receipt": "Ver Comprovante",
  "cryptoAdmin.noReceipt": "Ver Comprovante",
  "cryptoAdmin.downloadReceipt": "Baixar Comprovante",

  // WithdrawAdmin translations
  "withdrawAdmin.title": "Administração de Saques",
  "withdrawAdmin.subtitle": "Administração",
  "withdrawAdmin.email": "Email",
  "withdrawAdmin.cpf": "CPF",
  "withdrawAdmin.date": "Data",
  "withdrawAdmin.amount": "Valor",
  "withdrawAdmin.actions": "Ações",
  "withdrawAdmin.confirmSuccess": "Saque confirmado com sucesso",
  "withdrawAdmin.rejectSuccess": "Saque reprovado com sucesso",
  "withdrawAdmin.confirmError": "Erro ao confirmar o saque",
  "withdrawAdmin.rejectError": "Erro ao reprovar o saque",
  "withdrawAdmin.loadingTransactions": "Carregando transações...",
  "withdrawAdmin.previous": "Anterior",
  "withdrawAdmin.next": "Próxima",
  "withdrawAdmin.page": "Página",
  "withdrawAdmin.of": "de",
  "withdrawAdmin.viewReceipt": "Ver Comprovante",
  "withdrawAdmin.noReceipt": "Não há um comprovante para este saque",
  "withdrawAdmin.confirm": "Confirmar",
  "withdrawAdmin.reject": "Reprovar",

  // MyDeposits translations
  "myDeposits.title": "Depósitos Efetuados",
  "myDeposits.noDeposits": "Você ainda não possui depósitos registrados.",
  "myDeposits.addReceipt": "Adicionar",
  "myDeposits.viewReceipt": "Visualizar",
  "myDeposits.loadingReceipt": "Adicionando...",
  "myDeposits.errorAddingReceipt": "Erro ao adicionar comprovante",
  "myDeposits.successAddingReceipt": "Comprovante adicionado com sucesso!",
  "myDeposits.fetchError": "Erro ao buscar depósitos",
  "myDeposits.depositTypeDeposit": "Depósito",
  "myDeposits.depositTypePIX": "PIX",
  "myDeposits.id": "ID",
  "myDeposits.date": "Data",
  "myDeposits.time": "Hora",
  "myDeposits.type": "Tipo",
  "myDeposits.amount": "Valor",
  "myDeposits.status": "Status",
  "myDeposits.receipt": "Comprovante",

  // MyWithdraws translations
  "myWithdraws.title": "Saques Efetuados",
  "myWithdraws.noWithdraws": "Você ainda não possui saques realizados.",
  "myWithdraws.errorAddingReceipt": "Erro ao adicionar comprovante",
  "myWithdraws.successAddingReceipt": "Comprovante adicionado com sucesso!",
  "myWithdraws.fetchError": "Erro ao buscar saques",
  "myWithdraws.date": "Data",
  "myWithdraws.time": "Hora",
  "myWithdraws.amount": "Valor",
  "myWithdraws.status": "Status",
  "myWithdraws.receipt": "Comprovante",
  "myWithdraws.id": "ID",

  // Transactions translations
  "transactions.title": "Transações",
  "transactions.noTransactions":
    "Você ainda não possui transações em sua conta.",
  "transactions.noTransactionsCurrency": "Não há transações para esta moeda",
  "transactions.noTransactionsType": "Não há transações para este tipo",
  "transactions.previous": "Anterior",
  "transactions.next": "Próxima",
  "transactions.page": "Página",
  "transactions.of": "de",
  "transactions.deposit": "Depósito",
  "transactions.withdraw": "Saque",
  "transactions.spent": "Investimento",
  "transactions.earning": "Ganho",
  "transactions.commission": "Comissão",
  "transactions.date": "Data",
  "transactions.time": "Hora",
  "transactions.currency": "Moeda",
  "transactions.amount": "Valor",
  "transactions.type": "Tipo",
  "transactions.id": "ID",
  "transactions.all": "Todas",
  "transactions.errorNotLoggedIn":
    "Você precisa estar logado para ver as transações",

  "portfolio.title": "Portfólio",
  "portfolio.subtitle": "seu",
  "portfolio.project": "Projeto",
  "portfolio.pricePerShare": "Preço por Ação",
  "portfolio.investedValue": "Valor Investido",
  "portfolio.numberOfShares": "Número de Ações",
  "portfolio.lastPurchase": "Última Compra",
  "portfolio.shares": "Ações",
  "portfolio.noShares": "Você não possui nenhuma ação em seu portfólio ainda.",
  "portfolio.stake": "Fazer Stake",

  "stakeShareModal.header": "Fazer Stake de Shares",
  "stakeShareModal.originalPrice": "Valor original da Ação",
  "stakeShareModal.quantityPlaceholder": "Quantidade",
  "stakeShareModal.maxButton": "MAX",
  "stakeShareModal.totalValue": "Valor Total",
  "stakeShareModal.termsLink": "Termos e Condições",
  "stakeShareModal.termsText": "aplicáveis a esta operação.",
  "stakeShareModal.submitButton": "Fazer Stake",
  "stakeShareModal.errorMessage":
    "Por favor preencha todos os campos e garanta que a quantidade está dentro do limite",

  "login.title": "Entrar",
  "login.subtitle": "Acessar",
  "login.email": "Email",
  "login.emailPlaceholder": "Seu email",
  "login.password": "Senha",
  "login.passwordPlaceholder": "Sua senha",
  "login.submit": "Entrar",
  "login.forgotPassword": "Esqueceu a senha",
  "login.invalidCredentials": "Credenciais inválidas",

  "changePassword.pageTitle": "Esqueci a senha",
  "changePassword.resetTitle": "Resetar senha",
  "changePassword.forgotPassword": "Esqueci a senha",
  "changePassword.emailLabel": "Email",
  "changePassword.emailPlaceholder": "Seu email",
  "changePassword.emailSent": "E-mail de redefinição de senha enviado!",
  "changePassword.userNotFound": "Usuário não encontrado",
  "changePassword.emailError": "Erro ao enviar e-mail de redefinição de senha",
  "changePassword.loading": "Carregando",
  "changePassword.sendEmail": "Enviar Email",

  "cadastro.title": "Cadastro",
  "cadastro.subtitle": "Criar Conta",
  "cadastro.emailLabel": "Email",
  "cadastro.emailPlaceholder": "Seu email",
  "cadastro.passwordLabel": "Senha",
  "cadastro.passwordPlaceholder": "Sua senha",
  "cadastro.confirmPasswordLabel": "Confirmar Senha",
  "cadastro.confirmPasswordPlaceholder": "Confirme sua senha",
  "cadastro.submitButton": "Cadastrar",
  "cadastro.fillAllFields": "Preencha todos os campos",
  "cadastro.invalidEmail": "Email inválido",
  "cadastro.passwordsDoNotMatch": "Senhas não coincidem",
  "cadastro.emailInUse": "Email já está em uso",
  "cadastro.unknownError": "Erro desconhecido, tente novamente mais tarde",

  "editProject.title": "Título do Projeto",
  "editProject.titlePlaceholder": "Meu Projeto",
  "editProject.shortDescription": "Descrição Curta (PT-BR)",
  "editProject.shortDescriptionEN": "Descrição Curta (EN-US)",
  "editProject.shortDescriptionPlaceholder": "Minha Descrição Curta",
  "editProject.startDate": "Data de Início",
  "editProject.endDate": "Data de Término",
  "editProject.videoUrl": "URL do Vídeo do Projeto (YouTube)",
  "editProject.videoUrlPlaceholder": "https://youtube.com/...",
  "editProject.projectImage": "Imagem do Projeto",
  "editProject.thumbnailImage": "Imagem em Miniatura",
  "editProject.dueDiligence": "Documento de Due Diligence",
  "editProject.prospectus": "Documento de Prospecto",
  "editProject.presentation": "Documento de Apresentação",
  "editProject.editing": "Editando...",
  "editProject.editProject": "Editar Projeto",

  "investInfo.raised": "Arrecadado de",
  "investInfo.remainingShares": "Ações Disponíveis",
  "investInfo.invest": "Investir",
  "investInfo.monthlyReturn": "Retorno mensal",
  "investInfo.investing": "investindo",
  "investInfo.minInvestment": "Investimento Mínimo",
  "investInfo.shareValue": "Valor da Ação",
  "investInfo.monthlyProfit": "Lucro Mensal por Ação",
  "investInfo.totalSold": "Total Vendidas",
  "investInfo.description": "Descrição",
  "investInfo.project": "Projeto",

  // About translations
  "aboutPage.title": "Sobre Nós",
  "aboutPage.mainTitle": "Agregando valor ao seu negócio, tornando-o valioso",
  "aboutPage.subTitle": "Sobre Nós",
  "aboutPage.companyInfo": "Informações da Empresa",
  "aboutPage.investmentPlatform": "Uma plataforma de investimentos para você",
  "aboutPage.teamDescription":
    "Nossa equipe, especializada em investimentos estratégicos, conecta investidores e empreendedores. Rompemos padrões e expandimos possibilidades, criando um ambiente seguro e inovador para crescimento econômico.",
  "aboutPage.learnMore": "Saiba Mais",
  "aboutPage.invested": "Investidos",
  "aboutPage.launchedProjects": "Projetos Lançados",
  "aboutPage.investors": "Investidores",
  "aboutPage.annualReturn": "Retorno Anual",
  "aboutPage.howItWorks": "Como Funciona",
  "aboutPage.research": "Pesquisa",
  "aboutPage.researchDesc":
    "Identificamos oportunidades e analisamos investimentos promissores.",
  "aboutPage.evaluation": "Avaliação",
  "aboutPage.evaluationDesc":
    "Cada projeto passa por um rigoroso processo de análise e validação.",
  "aboutPage.investment": "Investimento",
  "aboutPage.investmentDesc":
    "Você investe de forma transparente e acompanha seus retornos.",

  //Contact translations
  "contactPage.title": "Contato",
  "contactPage.contactInfo": "Informações de ",
  "contactPage.website": "www.BarzelBank.com",
  "contactPage.phone": "+55 82 99635-0992",
  "contactPage.email": "kledson@BarzelBank.com",
  "contactPage.sendMessage": "Envie-nos uma mensagem",
  "contactPage.namePlaceholder": "Seu Nome",
  "contactPage.emailPlaceholder": "Seu Email",
  "contactPage.subjectPlaceholder": "Assunto",
  "contactPage.messagePlaceholder": "Sua Mensagem (máximo 144 caracteres)",
  "contactPage.submitButton": "Enviar Mensagem",
  "contactPage.fillAllFields": "Por favor, preencha todos os campos",
  "contactPage.messageLimit": "A mensagem deve ter no máximo 144 caracteres",
  "contactPage.invalidEmail": "Por favor, insira um email válido!",
  "contactPage.successMessage": "Mensagem enviada com sucesso!",
  "contactPage.failureMessage": "Falha ao enviar mensagem!",

  //Terms and Conditions translations
  "terms.title": "TERMOS E CONDIÇÕES DE USO – BARZEL BANK",
  "terms.intro":
    "Uma plataforma para investimentos tokenizados por meio de crowdfunding. Ao utilizar nossa plataforma, você ingressa em um ambiente virtual detido e controlado por OOBYOU TECNOLOGIA LTDA, inscrita no CNPJ/MF sob o número 37.319.709/0001-64, com sede na Avenida Comendador Gustavo Paiva, número 3506, Maceió, Alagoas, Brasil.",
  "terms.essential":
    'Estes Termos e Condições de Uso ("Termos") regem o uso de nossa plataforma e a participação em ofertas de investimento. É essencial a leitura e aceitação integral destes Termos antes do cadastro e uso da plataforma.',
  "terms.section1": "1. Definições Importantes",
  "terms.platform": "Plataforma Barzel Bank:",
  "terms.platformDescription": "um ambiente virtual acessível por meio de ",
  "terms.platformInvestments":
    "onde os usuários podem investir em tokens que representam ativos reais.",
  "terms.tokens": "Tokens:",
  "terms.tokensDescription":
    "representações digitais de frações de ativos tangíveis ou intangíveis disponíveis para compra e venda na plataforma.",
  "terms.users": "Usuários:",
  "terms.investor": "Investidor:",
  "terms.investorDescription":
    "uma pessoa física ou jurídica que adquire tokens como forma de investimento.",
  "terms.issuer": "Emissor:",
  "terms.issuerDescription":
    "uma pessoa jurídica que emite tokens representativos de seus projetos na plataforma.",
  "terms.asset": "Ativo:",
  "terms.assetDescription":
    "qualquer recurso com valor econômico, como empreendimentos imobiliários, empresas ou outros bens tokenizados.",
  "terms.section2": "2. Objetivo da Plataforma",
  "terms.objective":
    "A Barzel Bank democratiza o acesso a investimentos por meio da tokenização de ativos, em conformidade com a legislação vigente e regulações aplicáveis.",
  "terms.beforeInvesting":
    "Antes de realizar investimentos, o usuário deve ler atentamente os documentos complementares, tais como:",
  "terms.riskTerm": "Termo de Ciência de Risco.",
  "terms.guide": "Guia do Investidor.",
  "terms.section3": "3. Cadastro e Uso",
  "terms.registrationRequired":
    "O cadastro é obrigatório para acessar a plataforma e deve incluir informações verídicas e atualizadas. Os seguintes requisitos são aplicáveis:",
  "terms.adult": "Ser maior de 18 anos ou emancipado.",
  "terms.provideDocuments":
    "Fornecer documentos como CPF/CNPJ, endereço, e-mail e telefone.",
  "terms.kyc": "Concluir procedimentos de compliance (ex.: KYC).",
  "terms.userResponsibilities": "Responsabilidades do Usuário:",
  "terms.protectCredentials": "Proteger as credenciais de acesso.",
  "terms.updateInfo": "Manter as informações cadastrais atualizadas.",
  "terms.respectTerms": "Cumprir com estes Termos e as leis aplicáveis.",
  "terms.rightToRefuse":
    "A plataforma reserva-se o direito de recusar ou encerrar contas que violem os Termos.",
  "terms.section4": "4. Investimentos",
  "terms.investorAccess": "Os investidores têm acesso a:",
  "terms.dashboard": "Painel com ofertas e investimentos ativos.",
  "terms.history": "Histórico e valorização dos tokens adquiridos.",
  "terms.issuerAccess": "Os emissores podem:",
  "terms.manageProjects": "Gerenciar captações e projetos.",
  "terms.monitorTransactions":
    "Acompanhar transações realizadas pelos investidores.",
  "terms.provideFAQ": "Disponibilizar materiais explicativos (ex.: FAQs).",
  "terms.section5": "5. Taxas e Custos",
  "terms.freeRegistration":
    "O cadastro na plataforma é gratuito para investidores.",
  "terms.issuerFees":
    "Os emissores estão sujeitos a taxas de avaliação de projetos e comissões sobre captações concluídas.",
  "terms.capitalGains":
    "Ganhos de capital sobre tokens podem estar sujeitos a tributação conforme a legislação vigente.",
  "terms.section6": "6. Limitação de Responsabilidade",
  "terms.noGuarantees": "A Barzel Bank não garante:",
  "terms.financialSuccess":
    "Rentabilidade ou sucesso financeiro dos investimentos.",
  "terms.noFailures": "Ausência de falhas operacionais.",
  "terms.userLiability":
    "A responsabilidade por dados e transações é exclusivamente do usuário.",
  "terms.section7": "7. Propriedade Intelectual",
  "terms.ipNotice":
    "Todo o conteúdo da plataforma é protegido por leis de propriedade intelectual e é de propriedade exclusiva da Barzel Bank. O uso não autorizado está sujeito a penalidades legais.",
  "terms.section8": "8. Modificações e Encerramento",
  "terms.unilateralChanges":
    "A Barzel Bank pode atualizar unilateralmente estes Termos, notificando os usuários. O uso contínuo após as alterações implica na aceitação dos Termos atualizados.",
  "terms.accountClosure":
    "Os usuários podem solicitar o encerramento de sua conta a qualquer momento, desde que não possuam investimentos ativos em andamento.",
  "terms.section9": "9. Foro e Legislação Aplicável",
  "terms.jurisdiction":
    "Estes Termos são regidos pelas leis da República Federativa do Brasil. Qualquer disputa será resolvida no foro da comarca de São Paulo, SP, com exclusão de qualquer outro, por mais privilegiado que seja.",
  "terms.contact": "Contato",
  "terms.contactInfo":
    "Dúvidas ou sugestões? Entre em contato pelo e-mail: contato@barzelbank.com",
  "terms.copyright": "Barzel Bank LTDA, 2025. Todos os direitos reservados.",

  //Deposit Modal Translation
  "depositModal.selectCurrency": "SELECIONE A MOEDA",
  "depositModal.brlDeposit": "DEPÓSITO EM BRL",
  "depositModal.depositValue": "Valor do depósito",
  "depositModal.nextButton": "PRÓXIMO",
  "depositModal.brlDepositInstruction": "FAÇA O DEPÓSITO EM BRL",
  "depositModal.scanQrCode": "Leia o QR Code no app do seu banco",
  "depositModal.copyQrCode": "COPIAR QR CODE",
  "depositModal.attachReceipt": "Anexe seu comprovante de R$ {value}",
  "depositModal.selectFile": "SELECIONAR ARQUIVO",
  "depositModal.confirmButton": "CONFIRMAR",
  "depositModal.termsAndConditions":
    "Termos e Condições aplicaveis a este depósito.",
  "depositModal.transactionSuccess": "TRANSAÇÃO ADICIONADA COM SUCESSO",
  "depositModal.transactionError": "ERRO AO ADICIONAR TRANSAÇÃO",
  "depositModal.btcDeposit": "DEPÓSITO EM REDE BITCOIN",
  "depositModal.btcDepositInstruction": "Leia o QR Code para fazer o depósito",
  "depositModal.copyBitcoinAddress": "COPIAR ENDEREÇO BITCOIN",
  "depositModal.confirmBitcoinDeposit": "CONFIRMAR DEPÓSITO",
  "depositModal.usdtDeposit": "DEPÓSITO EM REDE USDT",
  "depositModal.usdtDepositInstruction": "Leia o QR Code para fazer o depósito",
  "depositModal.copyUsdtAddress": "COPIAR ENDEREÇO USDT",
  "depositModal.confirmUsdtDeposit": "CONFIRMAR DEPÓSITO",
  "depositModal.currencySelection.brl": "BRL (Real Brasileiro)",
  "depositModal.currencySelection.usdt": "USDT (Tether)",
  "depositModal.currencySelection.btc": "BTC (Bitcoin)",
  "depositModal.inputPlaceholder": "Digite o valor",
  "depositModal.valueButton.add250": "+R$ 250",
  "depositModal.valueButton.add1000": "+R$ 1000",
  "depositModal.valueButton.add5000": "+R$ 5000",
  "depositModal.loading": "Carregando...",
  "depositModal.loginRequired":
    "Você precisa estar logado para fazer um depósito",
  "depositModal.error.invalidTransactionValue":
    "Valor de transação inválido. Deve ser maior que 0.",
  "depositModal.error.pixGeneration": "Erro ao gerar código Pix",
  "depositModal.error.addingTransaction": "Erro ao adicionar transação",
  "depositModal.error.depositApi":
    "Erro ao fazer depósito, entre em contato com o suporte",
  "depositModal.toast.qrCodeCopied": "QR Code copiado com sucesso!",
  "depositModal.toast.bitcoinAddressCopied":
    "Endereço Bitcoin copiado com sucesso!",
  "depositModal.toast.usdtAddressCopied": "Endereço USDT copiado com sucesso!",

  //Invest Modal
  "investModal.close": "Fechar",
  "investModal.loading": "Carregando...",
  "investModal.successTitle": "Parabéns!!!",
  "investModal.successMessage": "Você acabou de se tornar um acionista em:",
  "investModal.successViewButton": "Visualizar",
  "investModal.successOkButton": "OK",
  "investModal.header": "COMPRAR AÇÕES",
  "investModal.sharePrice": "Preço por Ação:",
  "investModal.numberOfShares": "Número de Ações",
  "investModal.total": "Total:",
  "investModal.termsAndConditions":
    "Termos e Condições se aplicam a esta compra.",
  "investModal.buyButton": "COMPRAR",
  "investModal.error.unsupportedCurrency": "Moeda não suportada",
  "investModal.error.insufficientBalance":
    "Saldo insuficiente em {currency} para esta compra",
  "investModal.error.unauthenticatedUser": "Usuário não autenticado",
  "investModal.error.buyShares": "Falha ao comprar ações",

  //Withdraw Modal
  "withdrawModal.close": "Fechar",
  "withdrawModal.loading": "Carregando...",
  "withdrawModal.header": "SAQUE",
  "withdrawModal.withdrawValue": "Valor do saque",
  "withdrawModal.inputPlaceholder": "Digite o valor",
  "withdrawModal.nextButton": "PRÓXIMO",
  "withdrawModal.successTitle": "SAQUE REALIZADO COM SUCESSO",
  "withdrawModal.successOkButton": "OK",
  "withdrawModal.errorTitle": "ERRO AO REALIZAR SAQUE",
  "withdrawModal.error.invalidWithdrawValue":
    "Por favor, insira um valor de saque válido.",
  "withdrawModal.error.insufficientBalance":
    "Você não tem saldo suficiente para este saque.",
  "withdrawModal.error.notLoggedIn":
    "Você precisa estar logado para fazer um saque",
  "withdrawModal.error.processWithdraw": "Falha ao processar o saque.",

  // Buy Bitcoin Modal
  "buyBitcoinModal.close": "Fechar",
  "buyBitcoinModal.header": "COMPRAR BITCOIN",
  "buyBitcoinModal.selectCurrency": "Selecione a moeda",
  "buyBitcoinModal.brlOption": "BRL (Real)",
  "buyBitcoinModal.usdtOption": "USDT (Dólar)",
  "buyBitcoinModal.buyWithCurrency": "COMPRAR COM {currency}",
  "buyBitcoinModal.inputPlaceholder": "Digite o valor em {currency}",
  "buyBitcoinModal.buyAmount": "Compra ₿ {btcAmount} Bitcoin",
  "buyBitcoinModal.btcPrice": "1 Bitcoin (BTC) equivale a {currencySymbol} {btcPrice}",
  "buyBitcoinModal.remainingTime": "tempo restante {seconds} segundos",
  "buyBitcoinModal.nextButton": "PRÓXIMO",
  "buyBitcoinModal.termsAndConditions": "Termos e Condições aplicáveis a esta compra.",
  "buyBitcoinModal.confirmPurchase": "CONFIRMAR COMPRA",
  "buyBitcoinModal.confirmationText": "Confirmar Compra de ",
  "buyBitcoinModal.confirmationText2": " por ",
  "buyBitcoinModal.finalConfirmButton": "Confirmar",
  "buyBitcoinModal.cancelButton": "Cancelar",
  "buyBitcoinModal.transactionSuccess": "TRANSAÇÃO CRIADA",
  "buyBitcoinModal.transactionSuccessDetails": "SALDO ALOCADO NA ORDEM DE COMPRA",
  "buyBitcoinModal.transactionError": "ERRO",
  "buyBitcoinModal.transactionDetails": "Transação de Compra",
  "buyBitcoinModal.transactionDetails2": " por ",
  "buyBitcoinModal.noTransactionDetails": "Detalhes da transação não disponíveis.",
  "buyBitcoinModal.error.invalidAmount": "Digite um valor válido",
  "buyBitcoinModal.error.unsupportedCurrency": "Moeda não suportada",
  "buyBitcoinModal.error.insufficientBalance": "Saldo insuficiente em {currency} para esta compra",
  "buyBitcoinModal.error.apiError": "Erro ao realizar a compra. Tente novamente.",
  "buyBitcoinModal.successOkButton": "Sucesso",
  "buyBitcoinModal.error.minValue": "O valor deve ser pelo menos R$ 1000 para prosseguir.",

  //Sell bitcoin modal
  "sellBitcoinModal.title": "VENDER BITCOIN",
  "sellBitcoinModal.btcAmountLabel": "Quantidade de BTC para Vender",
  "sellBitcoinModal.confirmHeader": "CONFIRMAR VENDA",
  "sellBitcoinModal.successHeader": "TRANSAÇÃO CRIADA",
  "sellBitcoinModal.errorHeader": "ERROR",
  "sellBitcoinModal.error.invalidAmount": "Digite uma quantidade válida de BTC",
  "sellBitcoinModal.error.minimumAmount": "O valor deve ser pelo menos 10.",
  "sellBitcoinModal.error.insufficientBalance": "Saldo insuficiente de BTC para esta venda",
  "sellBitcoinModal.error.apiError": "Erro ao realizar a venda. Tente novamente.",
  "sellBitcoinModal.error.notAuthenticated": "Usuário não autenticado",
  "sellBitcoinModal.successMessage": "Você vendeu {btcAmount} BTC com sucesso!",
  "sellBitcoinModal.transactionDetails": "Transação De Venda",
  "sellBitcoinModal.transactionDetailsAmount": "₿ {btcAmount} por R$ {brlAmount}",
  "sellBitcoinModal.exchangeRate": "1 Bitcoin (BTC) equivale a R$ {btcPriceInBRL}",
  "sellBitcoinModal.countdownText": "{countdown} segundos restantes",
  "sellBitcoinModal.confirmSaleText": "Confirmar Venda de ₿ {btcAmount} por R$ {brlAmount}.",
  "sellBitcoinModal.terms": "Termos e Condições aplicáveis a esta venda.",
  "sellBitcoinModal.buttons.next": "PRÓXIMO",
  "sellBitcoinModal.buttons.confirm": "Confirmar",
  "sellBitcoinModal.buttons.cancel": "Cancelar",
  "sellBitcoinModal.buttons.ok": "OK",
  "sellBitcoinModal.placeholder.btcAmount": "Quantidade de BTC",
  "sellBitcoinModal.transactionDetailsUnavailable": "Detalhes da transação não disponíveis.",
  "sellBitcoinModal.confirmSaleTextPart1": "Confirmar Venda de ",
  "sellBitcoinModal.confirmSaleTextPart2": "por R$ {brlAmount}.",
  "sellBitcoinModal.transactionDetails1": "Transação de Venda",
  "sellBitcoinModal.transactionDetails2": "por R$ {brlAmount}",
  "sellBitcoinModal.exchangeRate": "1 Bitcoin (BTC) equivale a R$ {btcPriceInBRL}",
  "sellBitcoinModal.balanceAllocatedMessage": "O saldo foi alocado na ordem."
};
