import React from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import CartAmountToggle from "../../ShopComponents/CartAmountToggle";
import { Link } from "react-router-dom";
import { pageTitle } from "../../../helpers/PageTitle";
import LoaderComponent from "../../Loader/Loader";
import { useTranslation } from "../../../hooks/useTranslation";

import UserWallet from "./UserWallet";
import Offers from "./Offers";

export default function Marketplace() {
  const { translate } = useTranslation();
  pageTitle(translate("marketplace.title"));
  
  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("marketplace.title")}
        subTitle={translate("marketplace.subTitle")}
        variant="text-center"
        href="/shop"
      />
      
      <Spacing lg="75" md="60" />

      <UserWallet />
      {/* <CreateOrder /> */}
      <Spacing lg="70" md="70" />

      <Offers />

      <Spacing lg="120" md="50" />
    </>
  );
}
