import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import DropDown from "./DropDown";
import { auth, signOut } from "../../context/firebase";
import axios from "axios";
import DepositModal from "../Modals/DepositModal";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import LoaderComponent from "../Loader/Loader";
import WithdrawModal from "../Modals/WithdrawModal";
import { useTranslation } from "../../hooks/useTranslation";

export default function Header({ colorVariant, cart }) {
  const {
    cryptoPrices,
    userBalance,
    updateUserBalance,
    isLogged,
    updateLoginStatus,
    setAccessLevel,
    updateHeader,
    profilePicture,
    setProfilePicture,
    updateCryptoPrices,
  } = useContext(UserContext);

  const { translate, language, changeLanguage } = useTranslation();

  const [sharesBalance, setSharesBalance] = useState(0);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isWithdrawModal, setIsWithdrawModal] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState({
    brl: 0,
    btc: 0,
    usdt: 0,
    shares: 0,
    pending: 0,
  });
  const [isSmallScreen, setIsSmallscreen] = useState(window.innerWidth < 880);
  const [isMediumScreen, setIsMediumscreen] = useState(
    window.innerWidth < 1200
  );
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showBrlValue, setShowBrlValue] = useState({
    btc: false,
    usdt: false,
    brl: false,
    shares: false,
    pending: false,
  });
  const dropdownRef = useRef(null);
  const balanceDropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallscreen(window.innerWidth < 880);
      setIsMediumscreen(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const existingToken = localStorage.getItem("userToken");
    if (existingToken) {
      updateLoginStatus(true);

      fetch("https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${existingToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Invalid user");
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            updateUserBalance({
              brl: parseFloat(data.balance || 0) / 100,
              btc: parseFloat(data.btcBalance || 0) / 100000000,
              usdt: parseFloat(data.usdtBalance || 0) / 100,
              pending: parseFloat(data.pendingBalance || 0) / 100,
            });
            setBalance({
              brl: parseFloat(data.balance || 0) / 100,
              btc: parseFloat(data.btcBalance || 0) / 100000000,
              usdt: parseFloat(data.usdtBalance || 0) / 100,
              pending: parseFloat(data.pendingBalance || 0) / 100,
            });
          }
          if (data && data.avatar && data.avatar !== "none") {
            setProfilePicture(data.avatar);
          } else {
            setProfilePicture(process.env.PUBLIC_URL + "/perfil.png");
          }
          if (data && data.accessLevel === 3) {
            setAccessLevel(3);
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao obter saldo do usuário:", error);
          signOut(auth)
            .then(() => {
              localStorage.removeItem("userToken");
              localStorage.removeItem("tokenExpiration");
              updateLoginStatus(false);
              navigate("/");
            })
            .catch((err) => {
              console.error("Erro ao fazer logout:", err.message);
            });
        });
    }
  }, [isAuthorized, isLogged]);

  useEffect(() => {
    const fetchShares = async () => {
      try {
        const token = localStorage.getItem("userToken");

        if (!token) return;
        const response = await fetch(
          `https://getusershares-qj6yfdqbiq-uc.a.run.app`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        const actualCryptoPrices = await fetchCryptoPrices();

        const brlShares = data.shares
          .filter(
            (share) => share.currency !== "BTC" && share.currency !== "USDT"
          )
          .reduce((acc, share) => {
            const shareValue = share.numberOfShares * (share.sharePrice / 100);
            return acc + shareValue;
          }, 0);

        const btcShares = data.shares
          .filter((share) => share.currency === "BTC")
          .reduce((acc, share) => {
            return acc + share.numberOfShares * share.sharePrice;
          }, 0);

        const usdtShares = data.shares
          .filter((share) => share.currency === "USDT")
          .reduce((acc, share) => {
            const shareValue = share.numberOfShares * (share.sharePrice / 100);
            return acc + shareValue;
          }, 0);

        const sharesInBRL = brlShares;
        const sharesInBTC =
          parseFloat(btcShares / 100000000) * Number(actualCryptoPrices.btc);
        const sharesInUSDT = usdtShares * (actualCryptoPrices.usdt);

        const sharesTotal = sharesInBRL + sharesInUSDT + sharesInBTC;

        setSharesBalance(sharesTotal);
        setBalance((prev) => ({
          ...prev,
          shares: sharesTotal,
        }));

        updateUserBalance((prev) => ({
          ...prev,
          shares: sharesTotal,
        }));
      } catch (error) {
        console.error("Erro ao buscar ações:", error);
      }
    };

    if (isLogged) {
      fetchShares();
    }
  }, [isLogged]);

  const calculateTotalBalance = () => {
    let btcValue = (balance.btc || 0) * cryptoPrices.btc;
    let usdtValue = (balance.usdt || 0) * (cryptoPrices.usdt);
    let brlValue = balance.brl || 0;

    let sharesValue = sharesBalance || 0;

    let totalValue = btcValue + usdtValue + brlValue + sharesValue;
    if (language === "en") {
      totalValue = totalValue / (cryptoPrices.usdt / 100);
    }

    return totalValue;
  };

  const fetchCryptoPrices = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        "https://quotecryptopublic-qj6yfdqbiq-uc.a.run.app/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            usdtAmount: 100,
            btcAmount: 1,
          }),
        }
      );

      const data = await response.json();
      updateCryptoPrices({
        btc: data.btc.price,
        usdt: data.usdt.price,
      });

      return {
        btc: data?.btc?.price || 0,
        usdt: data?.usdt?.price || 0,
      };
    } catch (error) {
      console.error("Error fetching crypto prices:", error);
    }
  };

  useEffect(() => {
    fetchCryptoPrices();
    const interval = setInterval(fetchCryptoPrices, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        // console.log("Usuário desconectado");
        localStorage.removeItem("userToken");
        localStorage.removeItem("tokenExpiration");
        updateLoginStatus(false);
        navigate("/");
      })
      .catch((error) => console.error("Erro ao desconectar:", error));
  };

  const handleDepositModal = () => {
    setIsDepositModal(!isDepositModal);
  };

  const handleWithdrawModal = () => {
    setIsWithdrawModal(!isWithdrawModal);
  };

  const toggleBrlValue = (currency) => {
    setShowBrlValue((prev) => ({
      ...prev,
      [currency]: !prev[currency],
    }));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Close profile dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }

      // Close balance dropdown
      if (
        balanceDropdownRef.current &&
        !balanceDropdownRef.current.contains(event.target)
      ) {
        setShowBrlValue({
          brl: false,
          btc: false,
          usdt: false,
          shares: false,
          pending: false,
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isDepositModal && <DepositModal close={handleDepositModal} />}
      {isWithdrawModal && <WithdrawModal close={handleWithdrawModal} />}
      <header
        className={`cs_site_header cs_style_1 cs_sticky_header ${
          colorVariant ? colorVariant : "cs_primary_color"
        } ${isSticky ? "cs_gescout_show" : ""}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link className="cs_site_branding" to="/">
                  <ImageLogo src={"/barzel-logo.png"} alt="Logo" />
                </Link>
              </div>
              <div className="cs_main_header">
                <div className="cs_nav cs_medium cs_primary_font">
                  <ul
                    className={`${
                      mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                    }`}
                    modal
                  >
                    <li className="">
                      <Link to="/" onClick={() => setMobileToggle(false)}>
                        {translate("header.menu.home")}
                      </Link>
                      {location.pathname === "/" && (
                        <hr
                          style={{
                            width: isMediumScreen ? "40%" : "100%",
                            margin: "0",
                          }}
                        />
                      )}
                    </li>
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/profile"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.profile")}
                        </Link>
                        {location.pathname === "/profile" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && isAuthorized && (
                      <li>
                        <Link
                          to="/create-project"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.newProject")}
                        </Link>
                        {location.pathname === "/create-project" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && isAuthorized && (
                      <li>
                        <Link
                          to="/withdraw-admin"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.manageWithdraws")}
                        </Link>
                        {location.pathname === "/withdraw-admin" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && isAuthorized && (
                      <li>
                        <Link
                          to="/deposit-admin"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.manageDeposits")}
                        </Link>
                        {location.pathname === "/deposit-admin" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/balances"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.balance")}
                        </Link>
                        {location.pathname === "/balances" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          onClick={() => {
                            handleDepositModal();
                            setMobileToggle(false);
                          }}
                        >
                          {translate("header.menu.deposit")}
                        </Link>
                        {location.pathname === "/deposit" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/my-deposits?page=1&limit=10"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.deposits")}
                        </Link>
                        {location.pathname.startsWith("/my-deposits") && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          onClick={() => {
                            handleWithdrawModal();
                            setMobileToggle(false);
                          }}
                        >
                          {translate("header.menu.withdraw")}
                        </Link>
                        {location.pathname === "/withdraw" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/my-withdraws?page=1&limit=10"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.withdraws")}
                        </Link>
                        {location.pathname.startsWith("/my-withdraws") && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/transactions?page=1&limit=10"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.transactions")}
                        </Link>
                        {location.pathname.startsWith("/transactions") && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li>
                        <Link
                          to="/my-portfolio?page=1&limit=10"
                          onClick={() => setMobileToggle(false)}
                        >
                          {translate("header.menu.portfolio")}
                        </Link>
                        {location.pathname.startsWith("/my-portfolio") && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                    <li className="">
                      <Link
                        to="/projects"
                        onClick={() => setMobileToggle(false)}
                      >
                        {translate("header.menu.projects")}
                      </Link>
                      {location.pathname === "/projects" && (
                        <hr
                          style={{
                            width: isMediumScreen ? "40%" : "100%",
                            margin: "0",
                            color: "blue",
                          }}
                        />
                      )}
                    </li>{" "}
                    <li className="">
                      <Link
                        to="/marketplace"
                        onClick={() => setMobileToggle(false)}
                      >
                        {translate("header.menu.market")}
                      </Link>
                      {location.pathname === "/marketplace" && (
                        <hr
                          style={{
                            width: isMediumScreen ? "40%" : "100%",
                            margin: "0",
                            color: "blue",
                          }}
                        />
                      )}
                    </li>
                    {isMediumScreen && (
                      <li>
                        <Link
                          onClick={() => {
                            handleLogout();
                            setMobileToggle(false);
                          }}
                        >
                          {translate("header.menu.logout")}
                        </Link>
                        {location.pathname === "/logout" && (
                          <hr
                            style={{
                              width: isMediumScreen ? "40%" : "100%",
                              margin: "0",
                            }}
                          />
                        )}
                      </li>
                    )}
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs_menu_toggle cs_teggle_active"
                        : "cs_menu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </div>
              </div>
              <div className="cs_main_header_right">
                {isLogged && isLoading ? (
                  <LoaderComponent size={35} borderThickness={6} />
                ) : (
                  <>
                    {cart && (
                      <a href="shop-cart" className="cs_header_cart">
                        {/* ... */}
                      </a>
                    )}
                    <div>
                      <StyledSelect
                        className="form-control"
                        value={language}
                        onChange={(e) => changeLanguage(e.target.value)}
                      >
                        <option value="pt">
                          {translate("language.selector.portuguese")}
                        </option>
                        <option value="en">
                          {translate("language.selector.english")}
                        </option>
                      </StyledSelect>
                    </div>
                    {isLogged ? (
                      <ProfileSection>
                        {isLogged && (
                          <Balances>
                            <UserBalanceCard4
                              ref={balanceDropdownRef}
                              onClick={() =>
                                setShowBrlValue((prev) => ({
                                  brl: !prev.brl,
                                  btc: false,
                                  usdt: false,
                                  shares: false,
                                  pending: false,
                                }))
                              }
                            >
                              <span>
                                {language === "en" ? "$" : "R$"}
                                {calculateTotalBalance()
                                  .toFixed(2)
                                  .toLocaleString()}
                              </span>
                              {showBrlValue.brl && (
                                <FloatingValue>
                                  <div>
                                    {translate("header.balance.brl")}:{" "}
                                    <span>
                                      R${" "}
                                      {(balance.brl || 0)
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </span>
                                  </div>
                                  <div>
                                    {translate("header.balance.btc")}:{" "}
                                    <span>
                                      R${" "}
                                      {((balance.btc || 0) * cryptoPrices.btc)
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </span>
                                  </div>
                                  <div>
                                    {translate("header.balance.usdt")}:{" "}
                                    <span>
                                      ${" "}
                                      {(
                                        (balance.usdt || 0) 
                                      )
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </span>
                                  </div>
                                  <div>
                                    {translate("header.balance.invested")}:{" "}
                                    <span>
                                      R${" "}
                                      {(sharesBalance || balance.shares || 0)
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </span>
                                  </div>
                                  <div className="pending">
                                    {translate("header.balance.pending")}:{" "}
                                    <span>
                                      R${" "}
                                      {(balance.pending || 0)
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </span>
                                  </div>
                                  <div
                                    className="pending"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <a
                                      onClick={() => navigate("/balances")}
                                      style={{
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        color: "#fff",
                                      }}
                                    >
                                      {translate("header.balance.details")}
                                    </a>
                                  </div>
                                </FloatingValue>
                              )}
                            </UserBalanceCard4>
                          </Balances>
                        )}

                        {!isSmallScreen && (
                          <UserBalanceCard2 onClick={handleDepositModal}>
                            {translate("header.menu.deposit")}
                          </UserBalanceCard2>
                        )}

                        {!isSmallScreen && (
                          <UserBalanceCard2 onClick={handleWithdrawModal}>
                            {translate("header.menu.withdraw")}
                          </UserBalanceCard2>
                        )}
                        {!isSmallScreen && (
                          <ProfilePicture
                            ref={dropdownRef}
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                          >
                            <img
                              src={profilePicture}
                              style={{
                                width: isSmallScreen ? "50px" : "60px",
                                height: isSmallScreen ? "50px" : "60px",
                                marginLeft: isSmallScreen ? "18px" : "0",
                                marginTop: isSmallScreen ? "5px" : "0px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  process.env.PUBLIC_URL + "/perfil.png";
                              }}
                            />
                            <div />
                            {dropdownOpen && !isMediumScreen && (
                              <DropdownWrapper>
                                <DropdownMenu>
                                  <Button onClick={() => navigate("/profile")}>
                                    {translate("header.menu.profile")}
                                  </Button>

                                  <Button onClick={() => navigate("/balances")}>
                                    {translate("header.menu.balance")}
                                  </Button>

                                  {isAuthorized && (
                                    <>
                                      <Button
                                        onClick={() =>
                                          navigate("/create-project")
                                        }
                                      >
                                        {translate("header.menu.newProject")}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          navigate("/deposit-admin")
                                        }
                                      >
                                        {translate(
                                          "header.menu.manageDeposits"
                                        )}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          navigate("/withdraw-admin")
                                        }
                                      >
                                        {translate(
                                          "header.menu.manageWithdraws"
                                        )}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          navigate("/crypto-admin")
                                        }
                                      >
                                        {translate(
                                          "header.menu.manageCrypto"
                                        )}
                                      </Button>
                                    </>
                                  )}

                                  <Button
                                    onClick={() =>
                                      navigate("/my-deposits?page=1&limit=10")
                                    }
                                  >
                                    {translate("header.menu.deposits")}
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      navigate("/my-withdraws?page=1&limit=10")
                                    }
                                  >
                                    {translate("header.menu.withdraws")}
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      navigate("/transactions?page=1&limit=10")
                                    }
                                  >
                                    {translate("header.menu.transactions")}
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      navigate("/my-portfolio?page=1&limit=10")
                                    }
                                  >
                                    {translate("header.menu.portfolio")}
                                  </Button>

                                  <Button onClick={handleLogout}>
                                    {translate("header.menu.logout")}
                                  </Button>
                                </DropdownMenu>
                              </DropdownWrapper>
                            )}
                          </ProfilePicture>
                        )}
                      </ProfileSection>
                    ) : (
                      <ButtonsSign>
                        <UserBalanceCard3
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/login")}
                        >
                          {translate("header.auth.login")}
                        </UserBalanceCard3>
                        <UserBalanceCard3
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/register")}
                        >
                          {translate("header.auth.register")}
                        </UserBalanceCard3>
                      </ButtonsSign>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const StyledSelect = styled.select`
  color: #fff !important;
  background-color: #121212 !important; // Cor de fundo igual ao UserBalanceCard4
  border: 1px solid #c09c5c !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;

  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 1;

  &:hover {
    transform: scale(1.03);
  }
`;

const ProfileSection = styled.div`
  margin-right: -10px;
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 880px) {
    gap: 0px;
  }

  @media (max-width: 340px) {
    margin-right: -15px;
  }
`;

const ButtonsSign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Balances = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const UserBalanceCard4 = styled.div.attrs({ tabIndex: 0 })`
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 1;

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 570px) {
    font-size: 12px;
  }

  @media (max-width: 340px) {
    margin-right: -15px;
  }
`;

const UserBalanceCard2 = styled.div`
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;
  cursor: pointer;
  background-color: #a07c4b;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.03);
  }
`;

const UserBalanceCard3 = styled.div`
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #c09c5c;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: #a07c4b;
    transform: scale(1.03);
  }

  @media (max-width: 390px) {
    padding: 8px 8px;
    font-size: 12px;
  }
`;

const ProfilePicture = styled.div.attrs({ tabIndex: 0 })`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 10px;
  background: url(${(props) => props.profilePicture});
  background-size: cover !important;
  background-position: center !important;
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -15px;
`;

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 25px; /* Adjusted to appear below the profile picture */
  left: 30px;
  padding: 10px;
  background-color: rgba(
    30,
    30,
    30,
    0.95
  ); /* Slightly transparent dark background */
  border: 1px solid #444; /* Optional border for elegance */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow for elegance */
  z-index: 10;
  width: 160px; /* Adjust width to be more compact */
  animation: ${fadeInSlideDown} 0.3s ease forwards;
`;

const Button = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #c89c5c, #a07c4b); /* Subtle gradient */
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: linear-gradient(
      135deg,
      #a07c4b,
      #8a6b3f
    ); /* Darker gradient on hover */
    transform: scale(1.05); /* Slightly larger on hover */
  }
`;

const ImageLogo = styled.img`
  width: 150px;

  @media (max-width: 430px) {
    width: 100px;
  }
`;

const FloatingValue = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9em;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  opacity: 0;
  animation: ${fadeInSlideDown} 0.3s ease forwards;

  div {
    margin: 4px 0;
    display: flex;
    justify-content: space-between;

    span {
      margin-left: auto;
      padding-left: 10px;
    }

    &.pending {
      color: #ffd700;
    }
  }
`;
