import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { FaClock, FaTimes } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { UserContext } from "../../context/UserContext";
import LoaderComponent from "../Loader/Loader";
import { useTranslation } from "../../hooks/useTranslation";

export default function BuyBitcoinModal({ close }) {
  const [stage, setStage] = useState("select");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [amount, setAmount] = useState("");
  const [btcAmount, setBtcAmount] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const { userBalance } = useContext(UserContext);
  const [cryptoPrices, setCryptoPrices] = useState({
    btc: 0,
    usdt: 0,
  });
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState(null);

  const { translate } = useTranslation();

  const btcPriceInUSD = cryptoPrices?.btc / (cryptoPrices?.usdt / 100);
  const btcPriceInBRL = cryptoPrices?.btc || 0;

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    setStage("input");
    setAmount("");
    setBtcAmount(0);
  };

  const handleAmountChange = (e) => {
    const currentValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const value = currentValue;
    setAmount(value);

    if (value) {
      setCountdown(15);
    }

    calculationAmount(value);
  };

  const calculationAmount = async (value) => {
    const cryptoPricesUpdated = await fetchCryptoPrices();
    if (
      selectedCurrency === "BRL" &&
      cryptoPricesUpdated.btc &&
      cryptoPricesUpdated.usdt
    ) {
      const calculatedBtc = value / cryptoPricesUpdated.btc;
      setBtcAmount(calculatedBtc);
    } else if (selectedCurrency === "USDT" && cryptoPricesUpdated.usdt) {
      const calculatedBtc = value / btcPriceInUSD;
      setBtcAmount(calculatedBtc);
    }
  };

  const handleConfirm = () => {
    if (!amount) {
      toast.error(translate("buyBitcoinModal.error.invalidAmount"));
      return;
    }

    const totalValue = Number(amount * 100);
    let availableBalance = 0;
    switch (selectedCurrency) {
      case "USDT":
        availableBalance = userBalance.usdt * 100;
        break;
      case "BRL":
        availableBalance = userBalance.brl * 100;
        break;
      default:
        toast.error(translate("buyBitcoinModal.error.unsupportedCurrency"));
        return;
    }

    if (totalValue > availableBalance) {
      toast.error(translate("buyBitcoinModal.error.insufficientBalance").replace("{currency}", selectedCurrency));
      return;
    }

     // Adicionando a condição para travar a operação se totalValue for menor que 100000
     if (totalValue < 100000) {
      toast.error(translate("buyBitcoinModal.error.minValue")); // Mensagem de erro para valor mínimo
      return;
    }

    setStage("confirm");
  };

  const handleFinalConfirm = async () => {
    setLoading(true);

    try {
      const app = getApp();
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (!user) {
        throw new Error(translate("buyBitcoinModal.error.apiError"));
      }

      const jwtToken = await user.getIdToken();

      const response = await fetch(
        "https://userbuycrypto-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            amount: selectedCurrency === "BRL" ? amount * 100 : amount,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(translate("buyBitcoinModal.error.apiError"));
      }

      const data = await response.json();
      setTransactionData(data);
      toast.success(translate("buyBitcoinModal.transactionSuccess"));

      setStage("BRL3");
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      toast.error(translate("buyBitcoinModal.transactionError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setCountdown(15);
    }

    if (countdown === 15) {
      calculationAmount(amount);
    }

    return () => clearTimeout(timer);
  }, [countdown, amount]);

  const fetchCryptoPrices = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        "https://quotecryptopublic-qj6yfdqbiq-uc.a.run.app/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            usdtAmount: 1,
            btcAmount: 0.1,
          }),
        }
      );

      const data = await response.json();
      setCryptoPrices({
        btc: data.btc.price,
        usdt: data.usdt.price,
      });

      return {
        btc: data.btc.price,
        usdt: data.usdt.price,
      };
    } catch (error) {
      console.error("Error fetching crypto prices:", error);
    }
  };

  useEffect(() => {
    fetchCryptoPrices();
  }, []);

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          <FaTimes size={24} color="#fff" />
        </div>

        {stage === "select" && (
          <>
            <Header style={{ textAlign: "center" }}>{translate("buyBitcoinModal.header")}</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader>{translate("buyBitcoinModal.selectCurrency")}</SubHeader>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <CurrencyButton onClick={() => handleCurrencySelect("BRL")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'left', width: 170 }}>
                  <Flag src={"/brazil-icon.png"} alt="Bandeira do Brasil" />
                  {translate("buyBitcoinModal.brlOption")}
                </div>
              </CurrencyButton>
              <CurrencyButton style={{ backgroundColor: "black" }} >
                <div style={{ color: "gray"}}>
                  <Flag src={"/usdt-icon.png"} alt="Icon do USDT" />
                  {translate("buyBitcoinModal.usdtOption")}
                </div>
              </CurrencyButton>
            </div>
          </>
        )}

        {stage === "input" && selectedCurrency && (
          <>
            <Header style={{ textAlign: "center" }}>
              {translate("buyBitcoinModal.buyWithCurrency").replace("{currency}", selectedCurrency)}
            </Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <Input
              type="text"
              placeholder={translate("buyBitcoinModal.inputPlaceholder").replace("{currency}", selectedCurrency)}
              value={amount ? (selectedCurrency === "BRL" ? `R$ ${amount}` : `$ ${amount}`) : ""}
              onChange={handleAmountChange}
              inputMode="numeric"
              max="900000"
              pattern="[0-9]+"
            />

            <SubHeader style={{ marginTop: "10px", textAlign: "center" }}>
              {translate("buyBitcoinModal.buyAmount").replace("{btcAmount}", btcAmount.toFixed(8))}
            </SubHeader>

            <SubHeader
              style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}
            >
              {translate("buyBitcoinModal.btcPrice")
                .replace("{currencySymbol}", selectedCurrency === "BRL" ? "R$" : "$")
                .replace("{btcPrice}", parseFloat(btcPriceInBRL).toFixed(2))}
            </SubHeader>

            {countdown > 0 && amount !== "" && (
              <SubHeader
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 14,
                }}
              >
                <FaClock style={{ marginRight: "8px" }} />
                {translate("buyBitcoinModal.remainingTime").replace("{seconds}", countdown)}
              </SubHeader>
            )}

            <Button
              onClick={handleConfirm}
              style={{ backgroundColor: "#00C160" }}
            >
              {translate("buyBitcoinModal.nextButton")}
            </Button>
            <TermsText>
              <TermsLink
                onClick={() => window.open("/terms-and-conditions", "_blank")}
              >
                {translate("buyBitcoinModal.termsAndConditions")}
              </TermsLink>
            </TermsText>

            {selectedCurrency === "BRL" && (
              <SubHeader
                style={{ textAlign: "center", fontSize: 14, marginTop: "20px" }}
              >
                {translate("buyBitcoinModal.btcPrice")
                  .replace("{currencySymbol}", "$")
                  .replace("{btcPrice}", btcPriceInUSD.toFixed(2))}
              </SubHeader>
            )}
          </>
        )}

        {stage === "confirm" && (
          <>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <LoaderComponent />
              </div>
            ) : (
              <>
                <Header style={{ textAlign: "center" }}>
                  {translate("buyBitcoinModal.confirmPurchase")}
                </Header>
                <div
                  style={{
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#c09c5c",
                    marginBottom: "20px",
                  }}
                ></div>
                <SubHeader style={{ textAlign: "center" }}>
                    {translate("buyBitcoinModal.confirmationText")} <p>${btcAmount.toFixed(8)} {translate("buyBitcoinModal.confirmationText2")} {selectedCurrency === "BRL" ? `R$ ${amount}` : `$ ${amount}`}</p>
                </SubHeader>

                {countdown > 0 && amount !== "" && (
              <SubHeader
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 14,
                }}
                  >
                    <FaClock style={{ marginRight: "8px" }} />
                    {translate("buyBitcoinModal.remainingTime").replace("{seconds}", countdown)}
                  </SubHeader>
            )}

                  <SubHeader
                  style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}
                >
              {translate("buyBitcoinModal.btcPrice")
                .replace("{currencySymbol}", selectedCurrency === "BRL" ? "R$" : "$")
                .replace("{btcPrice}", parseFloat(btcPriceInBRL).toFixed(2))}
            </SubHeader>
                <Button
                  onClick={handleFinalConfirm}
                  style={{ backgroundColor: "#26A17B", marginTop: "10px" }}
                > 
                  {translate("buyBitcoinModal.finalConfirmButton")}
                </Button>

                <Button
                  style={{ backgroundColor: "red", marginTop: "10px" }}
                  onClick={() => setStage("input")}
                >
                  {translate("buyBitcoinModal.cancelButton")}
                </Button>
              </>
            )}
          </>
        )}

        {stage === "BRL3" && (
          <>
            <Header style={{ textAlign: "center" }}>
              {transactionData && transactionData.success ? (
                translate("buyBitcoinModal.transactionSuccess")
              ) : (
                translate("buyBitcoinModal.transactionError")
              )}
            </Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader style={{ textAlign: "center" }}>
              {transactionData && transactionData.success ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Flag 
                    src={"/bitcoin-icon.svg"} 
                    alt="Icon do Bitcoin" 
                    style={{ width: "80px", height: "80px" }}
                  />

                  <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {translate("buyBitcoinModal.transactionDetails")} <p>${btcAmount.toFixed(8)} {translate("buyBitcoinModal.transactionDetails2")} {selectedCurrency === "BRL" ? `R$ ${amount}` : `$ ${amount}`}</p>
                  </p>

                  <SubHeader
                    style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}
                  >
                    {translate("buyBitcoinModal.btcPrice")
                      .replace("{currencySymbol}", selectedCurrency === "BRL" ? "R$" : "$")
                      .replace("{btcPrice}", parseFloat(btcPriceInBRL).toFixed(2))}
                  </SubHeader>
                  
                  <p
                    style={{ textAlign: "center", fontSize: 12, marginTop: "10px", marginBottom: "-16px", color: "#ff0000" }}
                  >
                    {translate("buyBitcoinModal.transactionSuccessDetails")}
                  </p>
                </div>
              ) : (
                <strong>{translate("buyBitcoinModal.noTransactionDetails")}</strong>
              )}
            </SubHeader>
            <Button onClick={close} style={{ marginTop: "10px" }}>{translate("buyBitcoinModal.successOkButton")}</Button>
          </>
        )}
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 450px;
  background-color: #121212;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  overflow: auto;

  @media (max-width: 500px) {
    height: 300px;
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const CurrencyButton = styled.button`
  padding: 15px;
  width: 100%;

  background-color: #333333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  &:hover {
    background-color: #444444;
  }

  img {
    margin-right: 10px;
  }
`;

const Flag = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;
