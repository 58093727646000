import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../context/firebase";
import { UserContext } from "../../context/UserContext";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { useTranslation } from "../../hooks/useTranslation";
import { FaTimes } from "react-icons/fa";

export default function WithdrawModal({ close }) {
  const [success, setSuccess] = useState(false);
  const [stage, setStage] = useState(0);
  const [withdrawValue, setWithdrawValue] = useState(null);
  const { userBalance } = useContext(UserContext);
  const {translate} = useTranslation();

  useEffect(() => {}, []);

  const handleNext = async () => {
    if (!withdrawValue || withdrawValue <= 0) {
      toast.error(translate("withdrawModal.error.invalidWithdrawValue"));
      return;
    }

    if (withdrawValue > userBalance.brl) {
      toast.error(translate("withdrawModal.error.insufficientBalance"));
      return;
    }

    try {
      setStage("loading");

      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error(translate("withdrawModal.error.notLoggedIn"));
        return;
      }

      const response = await fetch(
        "https://userwithdraw-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: withdrawValue * 100, 
          }),
        }
      );
      

      if (!response.ok) {
        const errorMessage = await response.text();
        toast.error(`Erro ao fazer saque: ${errorMessage}`);
        setStage(2);
        throw new Error(`Erro HTTP! status: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem("currentWithdrawId", data.transactionId);

      setTimeout(() => {
        setStage(1);
      }, 1000);
    } catch (error) {
      console.error(translate("withdrawModal.error.processWithdraw"), error);
      toast.error(translate("withdrawModal.error.processWithdraw"));
      setStage(2);
    }
  };

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          <FaTimes size={24} color="#fff" />
        </div>
        {stage === "loading" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <LoaderComponent />
          </div>
        )}
        {stage === 0 && (
          <>
            <Header style={{ textAlign: "center" }}>{translate("withdrawModal.header")}</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>

            <SubHeader style={{ textAlign: "center" }}>
              {translate("withdrawModal.withdrawValue")}
            </SubHeader>
            <Input
              placeholder={translate("withdrawModal.inputPlaceholder")}
              value={withdrawValue ? `R$ ${withdrawValue}` : ""}
              type="text"
              inputMode="numeric"
              max="900000"
              pattern="[0-9]+"
              onChange={(e) =>
                setWithdrawValue(
                  parseInt(e.target.value.replace(/\D/g, ""), 10) || null
                )
              }
            />

            <Button onClick={handleNext} style={{ backgroundColor: "#00C160" }}>
              {translate("withdrawModal.nextButton")}
            </Button>
          </>
        )}
        {stage === 1 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop:'150px'
              }}
            >
              <Header style={{ textAlign: "center" }}>{translate("withdrawModal.successTitle")}</Header>
            </div>
            <Button style={{marginBottom:'20px'}} onClick={close}>{translate("withdrawModal.successOkButton")}</Button>
          </>
        )}
        {stage === 2 && (
          <>
            <Header
              style={{
                marginTop: "18rem",
                textAlign: "center",
              }}
            >
              <span style={{ color: "red" }}>{translate("withdrawModal.errorTitle")}</span>
            </Header>
            <Button onClick={close}>{translate("withdrawModal.successOkButton")}</Button>
          </>
        )}
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 450px;
  background-color: #121212;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  @media (max-width: 500px) {
    height: 500px;
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;

  span {
    color: #c09c5c;
  }
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 340px) {
    font-size: 1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;

  /* Estiliza o input number para remover as setas */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield; /* Para Firefox */
  }
`;

const ButtonCopy = styled.button`
  width: 50%;
  margin-inline: auto;
  padding: 10px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: 1px solid #c09c5c;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -2rem;

  @media (max-width: 545px) {
    margin-top: -1.2rem;
  }

  &:hover {
    background-color: #c09c5c;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const ValueButton = styled.button`
  background-color: #333333;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
  margin-inline: auto;

  &:hover {
    background-color: #444444;
  }
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;
