import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../Spacing";
import { UserContext } from "../../../context/UserContext";
import LoaderComponent from "../../Loader/Loader";
import { useTranslation } from "../../../hooks/useTranslation";
import BuyBitcoinModal from "../../Modals/BuyBitcoinModal";
import SellBitcoinModal from "../../Modals/SellBitcoinModal";

const Dashboard = () => {
  const { translate } = useTranslation();
  const { userBalance, cryptoPrices, updateUserBalance } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sharesBalanceBRL, setSharesBalanceBRL] = useState(0);
  const [sharesBalanceBTC, setSharesBalanceBTC] = useState(0);
  const [sharesBalanceUSDT, setSharesBalanceUSDT] = useState(0);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [isSellModalOpen, setIsSellModalOpen] = useState(false);

  const fetchShares = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) return;

      const response = await fetch(
        `https://getusershares-qj6yfdqbiq-uc.a.run.app`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      const { brlShares, btcShares, usdtShares } = data.shares.reduce((acc, share) => {
        const shareValue = share.numberOfShares * (share.sharePrice / 100);

        if (share.currency === 'BTC') {
          const shareBTC = share.numberOfShares * share.sharePrice
          acc.btcShares += shareBTC;
        } else if (share.currency === 'USDT') {
          acc.usdtShares += shareValue;
        } else {
          acc.brlShares += shareValue;
        }
        return acc;
      }, { brlShares: 0, btcShares: 0, usdtShares: 0 });

      setSharesBalanceBTC(btcShares);
      setSharesBalanceUSDT(usdtShares);
      setSharesBalanceBRL(brlShares)

      updateUserBalance({
        ...userBalance,
        shares: brlShares + usdtShares + btcShares,
      });
    } catch (error) {
      console.error("Erro ao buscar ações:", error);
    }
  };

  useEffect(() => {
    fetchShares();
  }, []);

  function fetchUpdateUserBalance() {
    const existingToken = localStorage.getItem("userToken");

    if (existingToken) {
      fetch("https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${existingToken}`,
        },
      })
        .then((response) => {
          
          if (!response.ok) {
            throw new Error("Invalid user");
          }

          return response.json();
        })
        .then((data) => {
          if (data) {
            updateUserBalance({
              brl: parseFloat(data.balance || 0) / 100,
              btc: parseFloat(data.btcBalance || 0) / 100000000,
              usdt: parseFloat(data.usdtBalance || 0) / 100,
              pending: parseFloat(data.pendingBalance || 0) / 100,
            });
          }
        })
        .catch((error) => {
          console.error("Erro ao obter saldo do usuário:", error);
        });
    }
  }

  useEffect(() => {
    fetchUpdateUserBalance();
  }, [isBuyModalOpen, isSellModalOpen]);

  const calculateTotal = () => {
    try {
      if (
        !cryptoPrices?.btc ||
        !cryptoPrices?.usdt ||
        !userBalance ||
        typeof userBalance.btc !== "number" ||
        typeof userBalance.usdt !== "number" ||
        typeof userBalance.brl !== "number"
      ) {
        return "0.00";
      }

      const btcInBRL = userBalance.btc * Number(cryptoPrices.btc);
      const usdtInBRL = userBalance.usdt * (cryptoPrices.usdt);
      const brlBalance = userBalance.brl;

      const total = brlBalance + btcInBRL + usdtInBRL;
      return total.toFixed(2);
    } catch (err) {
      console.error("Erro ao calcular total:", err);
      return "0.00";
    }
  };

  const calculateTotalShares = () => {
    try {
      if (
        !cryptoPrices?.btc ||
        !cryptoPrices?.usdt ||
        !userBalance ||
        typeof userBalance.btc !== "number" ||
        typeof userBalance.usdt !== "number" ||
        typeof userBalance.brl !== "number"
      ) {
        return "0.00";
      }

      const sharesInBRL = sharesBalanceBRL;
      const sharesInBTC = parseFloat(sharesBalanceBTC / 100000000) * Number(cryptoPrices.btc);
      const sharesInUSDT = sharesBalanceUSDT * (cryptoPrices.usdt);
      const sharesTotal = sharesInBRL + sharesInUSDT + sharesInBTC;

      return sharesTotal.toFixed(2);
    } catch (err) {
      console.error("Erro ao calcular total:", err);
      return "0.00";
    }
  };

  const handleBuyBitcoin = () => {
    setIsBuyModalOpen(true);
  };

  const handleSellBitcoin = () => {
    setIsSellModalOpen(true);
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("dashboard.title")}
        subTitle={translate("dashboard.subtitle")}
        variant="text-left"
      />
      <InvestmentsPage>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              flexDirection: "column",
            }}
          >
            <h4>{translate("dashboard.loading")}</h4>
            <LoaderComponent size={80} borderThickness={5} />
          </div>
        ) : error ? (
          <ErrorMessage>{translate("dashboard.error")} {error}</ErrorMessage>
        ) : (
          <CardsContainer>
            <InvestmentCard>
              <CardHeader>{translate("dashboard.currentBalance")}</CardHeader>
              <CardBody>
                <BalanceItem
                  label={translate("dashboard.brlBalance")}
                  value={`R$ ${(userBalance.brl || 0).toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.btcBalance")}
                  value={`₿ ${userBalance.btc.toFixed(8)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.usdtBalance")}
                  value={`$ ${userBalance.usdt.toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.pendingBalance")}
                  value={`R$ ${(userBalance.pending || 0).toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.totalInBRL")}
                  value={`R$ ${calculateTotal()}`}
                />
              </CardBody>
            </InvestmentCard>

            <InvestmentCard>
              <CardHeader>{translate("dashboard.sharesBalance")}</CardHeader>
              <CardBody>
                <BalanceItem
                  label={translate("dashboard.sharesBRL")}
                  value={`R$ ${(sharesBalanceBRL || 0).toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.sharesBTC")}
                  value={`₿ ${(sharesBalanceBTC / 100000000) || 0}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.sharesUSDT")}
                  value={`$ ${(sharesBalanceUSDT || 0).toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.pendingShares")}
                  value={`R$ ${( 0).toFixed(2)}`}
                />
                <Divider />
                <BalanceItem
                  label={translate("dashboard.totalBRLInShares")}
                  value={`R$ ${calculateTotalShares()}`}
                />
              </CardBody>
            </InvestmentCard>
          </CardsContainer>
        )}

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10 }}> 
          <Button onClick={handleBuyBitcoin} style={{ backgroundColor: "#26A17B" }}>{translate("dashboard.buyBitcoin")}</Button>
          <Button onClick={handleSellBitcoin} style={{ backgroundColor: 'red' }}>{translate("dashboard.sellBitcoin")}</Button>
        </div>
      </InvestmentsPage>
      {isBuyModalOpen && <BuyBitcoinModal close={() => setIsBuyModalOpen(false)} />}
      {isSellModalOpen && <SellBitcoinModal close={() => setIsSellModalOpen(false)} />}
    </>
  );
};

const BalanceItem = ({ label, value }) => (
  <div>
    <p>
      <strong>{label}:</strong>
    </p>
    <Value>{value}</Value>
  </div>
);

const CardsContainer  = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 10px;
  }
`;

const InvestmentsPage = styled.section`
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #171717;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InvestmentCard = styled.article`
  background-color: #1f1f1f;
  border: 1px solid #c89c5c;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 30px;
  min-width: 350px;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 300px;
  }
`;

const CardHeader = styled.header`
  background-color: #c89c5c;
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  padding: 15px 0;
  border-radius: 8px 8px 0 0;
  letter-spacing: 1px;
`;

const CardBody = styled.div`
  padding: 20px 0;

  p {
    margin: 10px 0;
    color: #e0e0e0;
  }
`;

const Value = styled.p`
  font-size: 2.5em;
  color: #cccccc;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #c89c5c;
  margin: 20px 0;
  width: 85%;
`;

const LoadingMessage = styled.p`
  color: #ffc107;
  font-size: 1.2em;
  text-shadow: 0px 0px 4px rgba(255, 193, 7, 0.6);
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 0px 0px 4px rgba(255, 107, 107, 0.6);
`;

const Button = styled.button`
  background-color: #c89c5c;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a57a44;
  }
`;

export default Dashboard;
