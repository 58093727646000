import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "../../hooks/useTranslation";

const InvestCard = styled.div`
  height: 400px;
  width: 275px;
  background: ${(props) => `url(${props.thumbnailSrc})`};
  border-radius: 10px;
  margin-inline: auto;
  cursor: pointer;
`;

export default function HeroStyle2({
  miniTitle,
  title,
  subTitle,
  thumbnailSrc,
  mikeIcon,
}) {
  const navigate = useNavigate();
  const { translate } = useTranslation();

  
  return (
    <section
      className="cs_hero cs_style_2 cs_gray_bg position-relative"
      style={{ marginTop: "-75px" }}
    >
      <div className="container">
        <div className="cs_hero_text position-relative">
          <p className="cs_hero_top_title cs_medium cs_fs_18 cs_accent_color">
            <span className="cs_hero_mike">
              <img src={mikeIcon} alt="Mike" />
            </span>
            <span className="cs_hero_arrow cs_accent_color">
              <i className="fa-solid fa-chevron-right" />
            </span>
            <span className>{miniTitle}</span>
          </p>
          <h1 className="cs_hero_title cs_fs_68">{title}</h1>
        </div>
        <div className="row cs_gap_y_40 align-items-center">
          <div className="col-lg-6">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
                textAlign: "center",
              }}
            >
              <img
                style={{
                  borderRadius: "20px",
                }}
                src={thumbnailSrc}
              ></img>
              <h6>{translate("home.projects.items.startups.title")}</h6>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              style={{
                padding: "1rem",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  textAlign: "center",
                }}
              >
                <InvestCard thumbnailSrc={"/img3.png"} />
                <h6>{translate("home.projects.items.realState.title")}</h6>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  textAlign: "center",
                }}
              >
                <InvestCard thumbnailSrc={"/img2.png"} />
                <h6>{translate("home.projects.items.energy.title")}</h6>
              </div>
            </div>
          </div>
          <button
            style={{
              marginInline: "auto",
              width: "fit-content",
            }}
            className="cs_btn cs_style_1"
            onClick={() => navigate("/projects")}
          >
            {translate("home.hero.btnText")}
          </button>
        </div>
      </div>
    </section>
  );
}
