import React, { useState, useEffect, useCallback } from "react";
import Spacing from "../../Spacing";
import SectionHeading from "../../../components/SectionHeading";
import IconBox from "../../IconBox/index.jsx";
import LoaderComponent from "../../Loader/Loader";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";

const ProjectsGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  padding: 0 1rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
`;

export default function Projects({ darkMode }) {
  const { translate, language } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 12;
  const [loading, setLoading] = useState(true);

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://listcrowdfundprojects-qj6yfdqbiq-uc.a.run.app",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setProjects(data.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // Cálculo para paginação
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  return (
    <>
      <section className="cs_p76_full_width" id="projects">
        <Spacing lg="143" md="75" />
        <SectionHeading title={translate("projects.title")} subTitle={translate("projects.subtitle")} />
        <Spacing lg="85" md="45" />

        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderComponent />
          </div>
        ) : (
          <>
            <ProjectsGrid>
              {currentProjects?.map((project, index) => (
                <IconBox
                  projectId={project.id}
                  key={project.id}
                  iconSrc={project.imageUrl}
                  title={project.title}
                  currency={project.currency}
                  type={project.type}
                  subtitle={`Meta: R$ ${(
                    project.totalShares * project.sharePrice
                  ).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`}
                  progress={
                    (
                      (((project.soldShares + project.reservedShares) *
                        project.sharePrice) /
                        (project.totalShares * project.sharePrice)) *
                      100
                    ).toFixed(2) || 0
                  }
                  description={language === 'pt' ? project?.description : project?.descriptionEN}
                  shapeClass={`cs_shape_${index + 1}`}
                />
              ))}
            </ProjectsGrid>

            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("projects.previous")}
              </button>
              <span>
                {translate("projects.page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("projects.next")}
              </button>
            </div>
          </>
        )}
      </section>
    </>
  );
}
