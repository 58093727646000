export default {
  welcome: "Welcome",
  language: "Language",
  "home.pageTitle": "Tokenized Investment Platform",
  "home.hero.miniTitle": "Tokenized Investment Platform",
  "home.hero.title": "Make Great Investments",
  "home.hero.subTitle":
    "Transform your investment potential with our tokenized platform - access fractional ownership in premium startups, real estate, and VC funds previously reserved for institutional investors.",

  "home.projects.title": "Projects",
  "home.projects.subTitle": "Our",
  "home.projects.viewAll": "View all",
  "home.projects.items.startups.title": "Startups",
  "home.projects.items.startups.subTitle":
    "A moment provided. Interesting and you see everything and don't hear. Confident image she one what I not even less.",
  "home.projects.items.startups.btnText": "Invest",
  "home.projects.items.energy.title": "Energy",
  "home.projects.items.energy.subTitle":
    "A moment provided. Interesting and you see everything and don't hear. Confident image she one what I not even less.",
  "home.projects.items.energy.btnText": "Invest",
  "home.projects.items.realState.title": "Real Estate",
  "home.projects.items.realState.subTitle":
    "A moment provided. Interesting and you see everything and don't hear. Confident image she one what I not even less.",
  "home.projects.items.realState.btnText": "Invest",
  "home.projects.items.bigData.title": "Big Data Inc.",
  "home.projects.items.bigData.subTitle":
    "A moment provided. Interesting and you see everything and don't hear. Confident image she one what I not even less.",
  "home.projects.items.bigData.btnText": "Invest",

  "home.about.title": "Simplified Investment",
  "home.about.subTitle":
    "Unlock premium investment opportunities. Our tokenized platform allows you to own shares in high-potential assets at an accessible value. No bureaucracy, zero complications - just pure investment potential with full transparency and high liquidity.",
  "home.about.features.0": "Premium investment opportunities",
  "home.about.features.1": "No bureaucracy and complications",
  "home.about.features.2": "Fully tokenized operation",
  "home.about.features.3": "Above-market returns",
  "home.about.btnText": "Invest now",

  "home.funFacts.invested.title": "Invested",
  "home.funFacts.invested.number": "$72M",
  "home.funFacts.projects.title": "Launched Projects",
  "home.funFacts.projects.number": "65",
  "home.funFacts.investors.title": "Investors",
  "home.funFacts.investors.number": "1k",
  "home.funFacts.return.title": "Annual Return",
  "home.funFacts.return.number": "+23.96%",

  "home.video.title": "Discover new opportunities",

  "home.testimonials.items.0.text":
    "I'm impressed with Barzel's returns - my investments have been consistently yielding 1.99% per month. The platform makes it easy to track performance and reinvest profits.",
  "home.testimonials.items.0.avatarName": "Daniel Goldfarb",
  "home.testimonials.items.0.avatarDesignation": "Barzel Client",
  "home.testimonials.items.1.text":
    "Barzel is fantastic! Simple and transparent platform with great support. I was able to fund my project easily.",
  "home.testimonials.items.1.avatarName": "Gustavo Albuquerque",
  "home.testimonials.items.1.avatarDesignation": "CEO of Bosforo",
  "home.testimonials.items.2.text":
    "Barzel exceeds expectations! Their straightforward platform and exceptional support made funding my project very easy. Can't recommend enough!",
  "home.testimonials.items.2.avatarName": "Avraham Goldstein",
  "home.testimonials.items.2.avatarDesignation": "CEO of Delta",

  "home.awards.title": "Why invest?",
  "home.awards.subTitle": "Discover",
  "home.awards.items.0.title": "UI/UX Design of the Month",
  "home.awards.items.0.subTitle":
    "Investments through the platform bring some of the fastest returns in the segment.",
  "home.awards.items.0.date": "December 12, 2023",
  "home.awards.items.1.title": "CSS Design Award",
  "home.awards.items.1.subTitle":
    "Support entrepreneurship, generate jobs, and boost the economy.",
  "home.awards.items.1.date": "January 05, 2022",
  "home.awards.items.2.title": "Website of the Day",
  "home.awards.items.2.subTitle":
    "Connect with our community of investors and entrepreneurs.",
  "home.awards.items.2.date": "March 20, 2021",

  "home.blog.title": "Benefits of Investing with us",
  "home.blog.subTitle": "Read more",
  "home.blog.posts.0.title":
    "How to prevent fear from ruining your artistic business with confidence",
  "home.blog.posts.0.date": "Mar 07, 2023",
  "home.blog.posts.1.title": "Artistic mind will be great to create anything",
  "home.blog.posts.1.date": "Apr 22, 2023",
  "home.blog.posts.2.title": "AI will take over all human jobs in few years",
  "home.blog.posts.2.date": "May 13, 2023",
  "home.blog.posts.3.title":
    "Your agency needs to replace some people with artistic mind",
  "home.blog.posts.3.date": "Mar 15, 2023",

  "home.faq.title": "Frequently Asked Questions",
  "home.faq.subTitle": "FAQ",
  "home.faq.items.0.title": "01. What is the legal investment model?",
  "home.faq.items.0.content":
    "According to Brazilian law CVM 88, our investment platform operates under the crowdfunding model for investing in startups and small businesses. This regulation allows for public offering of securities by small companies through electronic platforms, providing a legal framework for retail investors to participate in early-stage investments.",
  "home.faq.items.1.title":
    "02. What are the risks of investing through Barzel?",
  "home.faq.items.1.content":
    "As with any investment, there are risks involved. Investing in startups and small businesses through our platform carries a high risk of partial or total capital loss. The securities offered are not guaranteed by the Credit Guarantee Fund (FGC) or any other insurance mechanism. It's important to diversify your investments and only invest money you can afford to lose.",
  "home.faq.items.2.title":
    "03. What are the advantages of investing through Barzel?",
  "home.faq.items.2.content":
    "Investing through Barzel offers several advantages: access to a curated selection of startups and small businesses, ability to invest with smaller amounts compared to traditional venture capital, potential for high returns, portfolio diversification, and opportunity to support innovative companies. Our platform also provides transparency and detailed information about each investment opportunity, as required by CVM 88.",
  "home.faq.items.3.title": "04. Is the investment process fully online?",
  "home.faq.items.3.content":
    "Yes, the entire investment process is conducted online through our platform. This includes browsing investment opportunities, conducting due diligence, making investment decisions, and managing your portfolio. However, as per CVM 88 regulations, certain documents and confirmations may need to be provided electronically to meet investor verification requirements.",
  "home.faq.items.4.title": "05. Will I become a shareholder in the company?",
  "home.faq.items.4.content":
    "The type of security you receive depends on the specific offering. In many cases, you will receive convertible notes or other forms of securities that can be converted into shares under certain conditions. Some offerings may provide direct equity. It's crucial to carefully read the terms of each investment opportunity. Remember that, as per CVM 88, your rights as an investor, including voting rights and economic rights, will be clearly described in the offering documents.",

  "home.cta.title":
    "Ready to unlock the future of investments through tokenization?",
  "home.cta.btnText": "Contact us",
  "home.hero.btnText": "Start Investing",

  // Header translations
  "header.menu.home": "Home",
  "header.menu.profile": "Profile",
  "header.menu.newProject": "New Project",
  "header.menu.manageWithdraws": "Manage Withdrawals",
  "header.menu.manageDeposits": "Manage Deposits",
  "header.menu.manageCrypto": "Manage Crypto",
  "header.menu.balance": "Balance",
  "header.menu.deposit": "Deposit",
  "header.menu.deposits": "Deposits",
  "header.menu.withdraw": "Withdraw",
  "header.menu.withdraws": "Withdrawals",
  "header.menu.transactions": "Transactions",
  "header.menu.portfolio": "Portfolio",
  "header.menu.market": "Market",
  "header.menu.logout": "Logout",
  "header.auth.login": "Login",
  "header.auth.register": "Register",
  "header.menu.projects": "Projects",

  "header.balance.details": "View Details",
  "header.balance.brl": "BRL",
  "header.balance.btc": "BTC",
  "header.balance.usdt": "USDT",
  "header.balance.invested": "Invested",
  "header.balance.pending": "Pending",

  // Language selector translations
  "language.selector.portuguese": "🇧🇷 Portuguese",
  "language.selector.english": "🇺🇸 English",

  // Projects
  "home.serviceSlider.IconBox.buttonLink": "Invest",

  // Footer translations
  "footer.address": "Av. Brg. Faria Lima, 3477 - Itaim Bibi",
  "footer.phone": "+55 82 99635 0992",
  "footer.email": "contact@barzel.com",
  "footer.services.title": "Services",
  "footer.links.title": "Links",
  "footer.newsletter.title": "Subscribe to Our Newsletter",
  "footer.newsletter.text":
    "We guarantee to send only relevant and noteworthy emails to you.",
  "footer.newsletter.placeholder": "Email Address",
  "footer.newsletter.button": "Send",
  "footer.copyright": "Copyright © 2024 Barzel.",
  "footer.policy.terms": "Terms of Use",
  "footer.policy.privacy": "Privacy Policy",
  "footer.links.home": "Home",
  "footer.links.about": "About",
  "footer.links.contact": "Contact",
  "footer.links.terms-and-conditions": "Terms and Conditions",
  "footer.services.internationalInvestments": "International Investments",
  "footer.services.tokenizationRWA": "Tokenization of RWA",
  "footer.services.bitcoinOTC": "Bitcoin OTC",

  // Marketplace translations
  "marketplace.title": "Marketplace",
  "marketplace.subTitle": "Store",

  // UserWallet translations
  "userWallet.title": "Your Shares",
  "userWallet.empty": "You do not have any shares in your portfolio yet.",
  "userWallet.sellButton": "SELL SHARE",
  "userWallet.originalValue": "Original Value",
  "userWallet.totalValue": "Total Valued",
  "userWallet.project": "Project",
  "userWallet.shares": "Shares",
  "userWallet.action": "Action",

  // Offers translations
  "offers.title": "Stock Offers Book",
  "offers.empty": "There are no offers available at the moment.",
  "offers.project": "Project",
  "offers.shares": "Shares",
  "offers.pricePerShare": "Price per Share",
  "offers.total": "Total",
  "offers.cancel": "CANCEL",
  "offers.buy": "BUY",
  "offers.previous": "Previous",
  "offers.next": "Next",
  "offers.page": "Page",
  of: "of",

  // Projects translations
  "projects.title": "Projects",
  "projects.subtitle": "Our",
  "projects.previous": "Previous",
  "projects.next": "Next",
  "projects.page": "Page",
  of: "of",

  // Profile translations
  "profile.title": "My Profile",
  "profile.subtitle": "Profile",
  "profile.sessionExpired": "Session expired, please log in again.",
  "profile.invalidSession": "Invalid session, please log in again.",
  "profile.avatarChange": "Change Avatar",
  "profile.name": "Name",
  "profile.cpf": "CPF",
  "profile.birthdate": "Birthdate",
  "profile.gender": "Gender",
  "profile.selectGender": "Select gender",
  "profile.male": "Male",
  "profile.female": "Female",
  "profile.other": "Other",
  "profile.nationality": "Nationality",
  "profile.address": "Address",
  "profile.phone": "Phone",
  "profile.income": "Annual Income",
  "profile.consultant": "Consultant",
  "profile.saveChanges": "Save Changes",
  "profile.edit": "Edit",
  "profile.sessionExpired": "Session expired, please log in again.",
  "profile.invalidSession": "Invalid session, please log in again.",
  "profile.uploadAvatarError": "Error uploading avatar",
  "profile.processImageError": "Error processing image",
  "profile.fetchProfileError": "Error loading profile data",
  "profile.updateProfileError": "Error updating profile",
  "profile.profileUpdated": "Profile updated successfully!",
  "profile.cropImageSuccess": "Click 'Save Changes' to confirm.",
  "profile.processImageError": "Error processing image",
  "profile.updateProfileSuccess": "Profile updated successfully!",
  "profile.updateProfileError": "Error updating profile",

  // Dashboard translations
  "dashboard.title": "Dashboard",
  "dashboard.subtitle": "Manage your balances",
  "dashboard.loading": "Loading...",
  "dashboard.error": "Error fetching shares:",
  "dashboard.currentBalance": "Current Balance",
  "dashboard.brlBalance": "BRL Balance",
  "dashboard.btcBalance": "BTC Balance",
  "dashboard.usdtBalance": "USDT Balance",
  "dashboard.pendingBalance": "Pending Balance",
  "dashboard.totalInBRL": "Total in BRL",
  "dashboard.sharesBalance": "Shares Balance",
  "dashboard.sharesBRL": "Shares BRL",
  "dashboard.sharesBTC": "Shares BTC",
  "dashboard.sharesUSDT": "Shares USDT",
  "dashboard.pendingShares": "Pending Shares",
  "dashboard.totalBRLInShares": "Total BRL in Shares",
  "dashboard.buyBitcoin": "Buy Bitcoin",
  "dashboard.sellBitcoin": "Sell Bitcoin",

  // CreateProject translations
  "createProject.title": "Create Project",
  "createProject.subtitle": "New Project",
  "createProject.projectTitle": "Project Title",
  "createProject.success": "Files added successfully!",
  "createProject.error": "Error updating project",
  "createProject.invalidSession": "Invalid session",
  "createProject.uploadError": "Error uploading file",
  "createProject.fileNotSelected": "No file selected for {fileType}",
  "createProject.urlNotObtained": "URL for {fileType} not obtained",
  "createProject.filesAdded": "Files added successfully!",
  "createProject.shortDescription": "Short Description (PT-BR)",
  "createProject.descriptionEN": "Short Description (EN-US)",
  "createProject.fullDescription": "Full Description (PT-BR)",
  "createProject.fullDescriptionEN": "Full Description (EN-US)",
  "createProject.endDate": "End Date",
  "createProject.videoUrl": "Project Video URL (YouTube)",
  "createProject.files": "Files",
  "createProject.presentation": "Presentation Document",
  "createProject.currency": "Currency",
  "createProject.type": "Type",
  "createProject.sharePrice": "Share Price",
  "createProject.totalShares": "Total Shares",
  "createProject.reservedShares": "Reserved Shares",
  "createProject.goalAmount": "Goal Amount",
  "createProject.startDate": "Start Date",
  "createProject.finishProject": "Finish Project",
  "createProject.back": "Back",
  "createProject.dueDiligence": "Due Diligence Document",
  "createProject.prospectus": "Prospectus Document",
  "createProject.presentation": "Presentation Document",
  "createProject.back": "Back",
  "createProject.finishProject": "Finish Project",
  "createProject.saveAndContinue": "Save and Continue",
  "createProject.thumbnailImage": "Thumbnail Image",

  // DepositAdmin translations
  "depositAdmin.title": "Deposit Administration",
  "depositAdmin.subtitle": "administration",
  "depositAdmin.email": "Email",
  "depositAdmin.cpf": "CPF",
  "depositAdmin.date": "Date",
  "depositAdmin.amount": "Amount",
  "depositAdmin.receipt": "Receipt",
  "depositAdmin.actions": "Actions",
  "depositAdmin.confirmSuccess": "Deposit confirmed successfully",
  "depositAdmin.rejectSuccess": "Deposit rejected successfully",
  "depositAdmin.confirmError": "Error confirming the deposit",
  "depositAdmin.rejectError": "Error rejecting the deposit",
  "depositAdmin.noReceipt": "No receipt for this deposit",
  "depositAdmin.previous": "Previous",
  "depositAdmin.next": "Next",
  "depositAdmin.page": "Page",
  "depositAdmin.loadingTransactions": "Loading transactions...",

  // CryptoAdmin translations
  "cryptoAdmin.title": "Crypto Administration",
  "cryptoAdmin.subtitle": "administration",
  "cryptoAdmin.email": "Email",
  "cryptoAdmin.cpf": "CPF",
  "cryptoAdmin.date": "Date",
  "cryptoAdmin.type": "Type",
  "cryptoAdmin.amount": "Amount",
  "cryptoAdmin.receipt": "Receipt",
  "cryptoAdmin.actions": "Actions",
  "cryptoAdmin.confirmSuccess": "Crypto confirmed successfully",
  "cryptoAdmin.rejectSuccess": "Crypto rejected successfully",
  "cryptoAdmin.confirmError": "Error confirming the crypto",
  "cryptoAdmin.rejectError": "Error rejecting the crypto",
  "cryptoAdmin.noReceipt": "No receipt for this crypto",
  "cryptoAdmin.previous": "Previous",
  "cryptoAdmin.next": "Next",
  "cryptoAdmin.page": "Page",
  "cryptoAdmin.loadingTransactions": "Loading transactions...",
  "cryptoAdmin.buy": "Buy",
  "cryptoAdmin.sell": "Sell",
  "crypto.Admin.button.purchase.confirm": "Confirm Purchase",
  "crypto.Admin.button.purchase.reject": "Reject Purchase",
  "crypto.Admin.button.sale.confirm": "Confirm Sale",
  "crypto.Admin.button.sale.reject": "Reject Sale",
  "cryptoAdmin.noReceipt": "View Receipt",
  "cryptoAdmin.downloadReceipt": "Download Receipt",

  // WithdrawAdmin translations
  "withdrawAdmin.title": "Withdraw Administration",
  "withdrawAdmin.subtitle": "Administration",
  "withdrawAdmin.email": "Email",
  "withdrawAdmin.cpf": "CPF",
  "withdrawAdmin.date": "Date",
  "withdrawAdmin.amount": "Amount",
  "withdrawAdmin.actions": "Actions",
  "withdrawAdmin.confirmSuccess": "Withdraw confirmed successfully",
  "withdrawAdmin.rejectSuccess": "Withdraw rejected successfully",
  "withdrawAdmin.confirmError": "Error confirming the withdraw",
  "withdrawAdmin.rejectError": "Error rejecting the withdraw",
  "withdrawAdmin.loadingTransactions": "Loading transactions...",
  "withdrawAdmin.previous": "Previous",
  "withdrawAdmin.next": "Next",
  "withdrawAdmin.page": "Page",
  "withdrawAdmin.of": "of",
  "withdrawAdmin.viewReceipt": "View Receipt",
  "withdrawAdmin.noReceipt": "No receipt for this withdraw",
  "withdrawAdmin.confirm": "Confirm",
  "withdrawAdmin.reject": "Reject",

  // MyDeposits translations
  "myDeposits.title": "Deposits Made",
  "myDeposits.noDeposits": "You have no registered deposits yet.",
  "myDeposits.addReceipt": "Add",
  "myDeposits.viewReceipt": "View",
  "myDeposits.loadingReceipt": "Adding...",
  "myDeposits.errorAddingReceipt": "Error adding receipt",
  "myDeposits.successAddingReceipt": "Receipt added successfully!",
  "myDeposits.fetchError": "Error fetching deposits",
  "myDeposits.depositTypeDeposit": "Deposit",
  "myDeposits.depositTypePIX": "PIX",
  "myDeposits.id": "ID",
  "myDeposits.date": "Date",
  "myDeposits.time": "Time",
  "myDeposits.type": "Type",
  "myDeposits.amount": "Amount",
  "myDeposits.status": "Status",
  "myDeposits.receipt": "Receipt",

  // MyWithdraws translations
  "myWithdraws.title": "Withdraws Made",
  "myWithdraws.noWithdraws": "You have no registered withdraws yet.",
  "myWithdraws.errorAddingReceipt": "Error adding receipt",
  "myWithdraws.successAddingReceipt": "Receipt added successfully!",
  "myWithdraws.fetchError": "Error fetching withdraws",
  "myWithdraws.date": "Date",
  "myWithdraws.time": "Time",
  "myWithdraws.amount": "Amount",
  "myWithdraws.status": "Status",
  "myWithdraws.receipt": "Receipt",
  "myWithdraws.id": "ID",

  // Transactions translations
  "transactions.title": "Transactions",
  "transactions.noTransactions":
    "You have no transactions in your account yet.",
  "transactions.noTransactionsCurrency":
    "There are no transactions for this currency",
  "transactions.noTransactionsType": "There are no transactions for this type",
  "transactions.previous": "Previous",
  "transactions.next": "Next",
  "transactions.page": "Page",
  "transactions.of": "of",
  "transactions.deposit": "Deposit",
  "transactions.withdraw": "Withdraw",
  "transactions.spent": "Investment",
  "transactions.earning": "Earning",
  "transactions.commission": "Commission",
  "transactions.date": "Date",
  "transactions.time": "Time",
  "transactions.currency": "Currency",
  "transactions.amount": "Amount",
  "transactions.type": "Type",
  "transactions.id": "ID",
  "transactions.all": "All",
  "transactions.errorNotLoggedIn":
    "You need to be logged in to view transactions",

  "portfolio.title": "Portfolio",
  "portfolio.subtitle": "your",
  "portfolio.project": "Project",
  "portfolio.pricePerShare": "Price per Share",
  "portfolio.investedValue": "Invested Value",
  "portfolio.numberOfShares": "Number of Shares",
  "portfolio.lastPurchase": "Last Purchase",
  "portfolio.shares": "Shares",
  "portfolio.noShares": "You do not have any shares in your portfolio yet.",
  "portfolio.stake": "Stake",

  "stakeShareModal.header": "Stake Shares",
  "stakeShareModal.originalPrice": "Original Share Price",
  "stakeShareModal.quantityPlaceholder": "Quantity",
  "stakeShareModal.maxButton": "MAX",
  "stakeShareModal.totalValue": "Total Value",
  "stakeShareModal.termsLink": "Terms and Conditions",
  "stakeShareModal.termsText": "applicable to this operation.",
  "stakeShareModal.submitButton": "Stake",
  "stakeShareModal.errorMessage":
    "Please fill in all fields and ensure the quantity is within the limit.",

  "login.title": "Login",
  "login.subtitle": "Access",
  "login.email": "Email",
  "login.emailPlaceholder": "Your email",
  "login.password": "Password",
  "login.passwordPlaceholder": "Your password",
  "login.submit": "Login",
  "login.forgotPassword": "Forgot password",
  "login.invalidCredentials": "Invalid credentials",

  "changePassword.pageTitle": "Forgot password",
  "changePassword.resetTitle": "Reset password",
  "changePassword.forgotPassword": "Forgot password",
  "changePassword.emailLabel": "Email",
  "changePassword.emailPlaceholder": "Your email",
  "changePassword.emailSent": "Password reset email sent!",
  "changePassword.userNotFound": "User not found",
  "changePassword.emailError": "Error sending password reset email",
  "changePassword.loading": "Loading",
  "changePassword.sendEmail": "Send Email",

  "cadastro.title": "Registration",
  "cadastro.subtitle": "Create Account",
  "cadastro.emailLabel": "Email",
  "cadastro.emailPlaceholder": "Your email",
  "cadastro.passwordLabel": "Password",
  "cadastro.passwordPlaceholder": "Your password",
  "cadastro.confirmPasswordLabel": "Confirm Password",
  "cadastro.confirmPasswordPlaceholder": "Confirm your password",
  "cadastro.submitButton": "Register",
  "cadastro.fillAllFields": "Fill in all fields",
  "cadastro.invalidEmail": "Invalid email",
  "cadastro.passwordsDoNotMatch": "Passwords do not match",
  "cadastro.emailInUse": "Email is already in use",
  "cadastro.unknownError": "Unknown error, please try again later",

  "editProject.title": "Project Title",
  "editProject.titlePlaceholder": "My Project",
  "editProject.shortDescription": "Short Description (PT-BR)",
  "editProject.shortDescriptionEN": "Short Description (EN-US)",
  "editProject.startDate": "Start Date",
  "editProject.endDate": "End Date",
  "editProject.videoUrl": "Project Video URL (YouTube)",
  "editProject.videoUrlPlaceholder": "https://youtube.com/...",
  "editProject.projectImage": "Project Image",
  "editProject.thumbnailImage": "Thumbnail Image",
  "editProject.dueDiligence": "Due Diligence Document",
  "editProject.prospectus": "Prospectus Document",
  "editProject.presentation": "Presentation Document",
  "editProject.editing": "Editing...",
  "editProject.editProject": "Edit Project",

  "investInfo.raised": "Raised from",
  "investInfo.remainingShares": "Remaining Shares",
  "investInfo.invest": "Invest",
  "investInfo.monthlyReturn": "Monthly Return",
  "investInfo.investing": "investing",
  "investInfo.minInvestment": "Minimum Investment",
  "investInfo.shareValue": "Share Value",
  "investInfo.monthlyProfit": "Monthly Profit per Share",
  "investInfo.totalSold": "Total Sold",
  "investInfo.description": "Description",
  "investInfo.project": "Project",

  //About translations
  "aboutPage.title": "About Us",
  "aboutPage.mainTitle": "Adding value to your business, making it valuable",
  "aboutPage.subTitle": "About Us",
  "aboutPage.companyInfo": "Company Information",
  "aboutPage.investmentPlatform": "An investment platform for you",
  "aboutPage.teamDescription":
    "Our team, specialized in strategic investments, connects investors and entrepreneurs. We break patterns and expand possibilities, creating a safe and innovative environment for economic growth.",
  "aboutPage.learnMore": "Learn More",
  "aboutPage.invested": "Invested",
  "aboutPage.launchedProjects": "Launched Projects",
  "aboutPage.investors": "Investors",
  "aboutPage.annualReturn": "Annual Return",
  "aboutPage.howItWorks": "How It Works",
  "aboutPage.research": "Research",
  "aboutPage.researchDesc":
    "We identify opportunities and analyze promising investments.",
  "aboutPage.evaluation": "Evaluation",
  "aboutPage.evaluationDesc":
    "Each project undergoes a rigorous process of analysis and validation.",
  "aboutPage.investment": "Investment",
  "aboutPage.investmentDesc":
    "You invest transparently and track your returns.",

  //Contact translations
  "contactPage.title": "Contact ",
  "contactPage.contactInfo": "Information",
  "contactPage.website": "www.BarzelBank.com",
  "contactPage.phone": "+55 82 99635-0992",
  "contactPage.email": "kledson@BarzelBank.com",
  "contactPage.sendMessage": "Send us a message",
  "contactPage.namePlaceholder": "Your Name",
  "contactPage.emailPlaceholder": "Your Email",
  "contactPage.subjectPlaceholder": "Subject",
  "contactPage.messagePlaceholder": "Your Message (max 144 characters)",
  "contactPage.submitButton": "Send Message",
  "contactPage.fillAllFields": "Please fill in all fields",
  "contactPage.messageLimit": "The message must be at most 144 characters",
  "contactPage.invalidEmail": "Please enter a valid email!",
  "contactPage.successMessage": "Message sent successfully!",
  "contactPage.failureMessage": "Failed to send message!",

  //Terms and Conditions translations
  "terms.title": "TERMS AND CONDITIONS OF USE – BARZEL BANK",
  "terms.intro":
    "A platform for tokenized investments through crowdfunding. By using our platform, you enter a virtual environment owned and controlled by OOBYOU TECNOLOGIA LTDA, registered under CNPJ/MF number 37.319.709/0001-64, headquartered at Avenida Comendador Gustavo Paiva, number 3506, Maceió, Alagoas, Brazil.",
  "terms.essential":
    'These Terms and Conditions of Use ("Terms") govern the use of our platform and participation in investment offerings. It is essential to read and fully accept these Terms before registering and using the platform.',
  "terms.section1": "1. Key Definitions",
  "terms.platform": "Barzel Bank Platform:",
  "terms.platformDescription": "a virtual environment accessible via ",
  "terms.platformInvestments":
    "where users can invest in tokens representing real assets.",
  "terms.tokens": "Tokens:",
  "terms.tokensDescription":
    "digital representations of fractions of tangible or intangible assets available for purchase and sale on the platform.",
  "terms.users": "Users:",
  "terms.investor": "Investor:",
  "terms.investorDescription":
    "an individual or legal entity that acquires tokens as an investment.",
  "terms.issuer": "Issuer:",
  "terms.issuerDescription":
    "a legal entity that issues tokens representing its projects on the platform.",
  "terms.asset": "Asset:",
  "terms.assetDescription":
    "any resource with economic value, such as real estate ventures, companies, or other tokenized goods.",
  "terms.section2": "2. Purpose of the Platform",
  "terms.objective":
    "Barzel Bank democratizes access to investments through the tokenization of assets, in compliance with current legislation and applicable regulations.",
  "terms.beforeInvesting":
    "Before making investments, the user must carefully read the complementary documents, such as:",
  "terms.riskTerm": "Risk Awareness Statement.",
  "terms.guide": "Investor's Guide.",
  "terms.section3": "3. Registration and Use",
  "terms.registrationRequired":
    "Registration is mandatory for accessing the platform and must include truthful and up-to-date information. The following requirements apply:",
  "terms.adult": "Be over 18 years old or emancipated.",
  "terms.provideDocuments":
    "Provide documents such as CPF/CNPJ, address, email, and phone number.",
  "terms.kyc": "Complete compliance procedures (e.g., KYC).",
  "terms.userResponsibilities": "User Responsibilities:",
  "terms.protectCredentials": "Protect access credentials.",
  "terms.updateInfo": "Keep registration information up-to-date.",
  "terms.respectTerms": "Comply with these Terms and applicable laws.",
  "terms.rightToRefuse":
    "The platform reserves the right to refuse or terminate accounts that violate the Terms.",
  "terms.section4": "4. Investments",
  "terms.investorAccess": "Investors have access to:",
  "terms.dashboard": "Dashboard showing active offers and investments.",
  "terms.history": "History and appreciation of acquired tokens.",
  "terms.issuerAccess": "Issuers can:",
  "terms.manageProjects": "Manage fundraising and projects.",
  "terms.monitorTransactions": "Monitor transactions made by investors.",
  "terms.provideFAQ": "Provide explanatory materials (e.g., FAQs).",
  "terms.section5": "5. Fees and Costs",
  "terms.freeRegistration":
    "Registration on the platform is free for investors.",
  "terms.issuerFees":
    "Issuers are subject to project evaluation fees and commission on completed fundraising.",
  "terms.capitalGains":
    "Capital gains on tokens may be subject to taxation under current legislation.",
  "terms.section6": "6. Limitation of Liability",
  "terms.noGuarantees": "Barzel Bank does not guarantee:",
  "terms.financialSuccess":
    "Profitability or financial success of investments.",
  "terms.noFailures": "Absence of operational failures.",
  "terms.userLiability":
    "Responsibility for data and transactions lies solely with the user.",
  "terms.section7": "7. Intellectual Property",
  "terms.ipNotice":
    "All content on the platform is protected by intellectual property laws and is the exclusive property of Barzel Bank. Unauthorized use is subject to legal penalties.",
  "terms.section8": "8. Modifications and Termination",
  "terms.unilateralChanges":
    "Barzel Bank may unilaterally update these Terms, notifying users. Continued use after changes implies acceptance of the updated terms.",
  "terms.accountClosure":
    "Users may request account termination at any time, provided they have no active investments in progress.",
  "terms.section9": "9. Jurisdiction and Applicable Law",
  "terms.jurisdiction":
    "These Terms are governed by the laws of the Federative Republic of Brazil. Any disputes will be resolved in the court of the district of São Paulo, SP, excluding any other jurisdiction, no matter how privileged it may be.",
  "terms.contact": "Contact",
  "terms.contactInfo":
    "Questions or suggestions? Contact us via email: contato@barzelbank.com",
  "terms.copyright": "Barzel Bank LTDA, 2025. All rights reserved.",

  //Deposit Modal Translation
  "depositModal.selectCurrency": "SELECT THE CURRENCY",
  "depositModal.brlDeposit": "BRL DEPOSIT",
  "depositModal.depositValue": "Deposit value",
  "depositModal.nextButton": "NEXT",
  "depositModal.brlDepositInstruction": "MAKE THE DEPOSIT IN BRL",
  "depositModal.scanQrCode": "Scan the QR Code in your bank app",
  "depositModal.copyQrCode": "COPY QR CODE",
  "depositModal.attachReceipt": "Attach your receipt of R$ {value}",
  "depositModal.selectFile": "SELECT FILE",
  "depositModal.confirmButton": "CONFIRM",
  "depositModal.termsAndConditions":
    "Terms and Conditions applicable to this deposit.",
  "depositModal.transactionSuccess": "TRANSACTION SUCCESSFULLY ADDED",
  "depositModal.transactionError": "ERROR ADDING TRANSACTION",
  "depositModal.btcDeposit": "BITCOIN NETWORK DEPOSIT",
  "depositModal.btcDepositInstruction": "Scan the QR Code to make the deposit",
  "depositModal.copyBitcoinAddress": "COPY BITCOIN ADDRESS",
  "depositModal.confirmBitcoinDeposit": "CONFIRM DEPOSIT",
  "depositModal.usdtDeposit": "USDT NETWORK DEPOSIT",
  "depositModal.usdtDepositInstruction": "Scan the QR Code to make the deposit",
  "depositModal.copyUsdtAddress": "COPY USDT ADDRESS",
  "depositModal.confirmUsdtDeposit": "CONFIRM DEPOSIT",
  "depositModal.currencySelection.brl": "BRL (Brazilian Real)",
  "depositModal.currencySelection.usdt": "USDT (Tether)",
  "depositModal.currencySelection.btc": "BTC (Bitcoin)",
  "depositModal.inputPlaceholder": "Enter the value",
  "depositModal.valueButton.add250": "+R$ 250",
  "depositModal.valueButton.add1000": "+R$ 1000",
  "depositModal.valueButton.add5000": "+R$ 5000",
  "depositModal.loading": "Loading...",
  "depositModal.loginRequired": "You need to be logged in to make a deposit",
  "depositModal.error.invalidTransactionValue":
    "Invalid transaction value. It must be greater than 0.",
  "depositModal.error.pixGeneration": "Error generating Pix code",
  "depositModal.error.addingTransaction": "Error adding transaction",
  "depositModal.error.depositApi":
    "Error making deposit, please contact support",
  "depositModal.toast.qrCodeCopied": "QR Code successfully copied!",
  "depositModal.toast.bitcoinAddressCopied":
    "Bitcoin address successfully copied!",
  "depositModal.toast.usdtAddressCopied": "USDT address successfully copied!",

  //Invest Modal
  "investModal.close": "Close",
  "investModal.loading": "Loading...",
  "investModal.successTitle": "Congratulations!!!",
  "investModal.successMessage": "You have just become a shareholder in:",
  "investModal.successViewButton": "View",
  "investModal.successOkButton": "OK",
  "investModal.header": "BUY SHARES",
  "investModal.sharePrice": "Price per Share:",
  "investModal.numberOfShares": "Number of Shares",
  "investModal.total": "Total:",
  "investModal.termsAndConditions":
    "Terms and Conditions apply to this purchase.",
  "investModal.buyButton": "BUY",
  "investModal.error.unsupportedCurrency": "Unsupported currency",
  "investModal.error.insufficientBalance":
    "Insufficient {currency} balance for this purchase",
  "investModal.error.unauthenticatedUser": "User not authenticated",
  "investModal.error.buyShares": "Failed to buy shares",

  //Withdraw Modal
  "withdrawModal.close": "Close",
  "withdrawModal.loading": "Loading...",
  "withdrawModal.header": "WITHDRAW",
  "withdrawModal.withdrawValue": "Withdraw value",
  "withdrawModal.inputPlaceholder": "Enter the value",
  "withdrawModal.nextButton": "NEXT",
  "withdrawModal.successTitle": "WITHDRAWAL SUCCESSFUL",
  "withdrawModal.successOkButton": "OK",
  "withdrawModal.errorTitle": "ERROR PROCESSING WITHDRAWAL",
  "withdrawModal.error.invalidWithdrawValue":
    "Please enter a valid withdrawal amount.",
  "withdrawModal.error.insufficientBalance":
    "You don't have enough balance for this withdrawal.",
  "withdrawModal.error.notLoggedIn":
    "You need to be logged in to make a withdrawal.",
  "withdrawModal.error.processWithdraw": "Failed to process the withdrawal.",

  //Buy Bitcoin Modal
  "buyBitcoinModal.close": "Close",
  "buyBitcoinModal.header": "BUY BITCOIN",
  "buyBitcoinModal.selectCurrency": "Select the currency",
  "buyBitcoinModal.brlOption": "BRL (Real)",
  "buyBitcoinModal.usdtOption": "USDT (Dollar)",
  "buyBitcoinModal.buyWithCurrency": "BUY WITH {currency}",
  "buyBitcoinModal.inputPlaceholder": "Enter the value in {currency}",
  "buyBitcoinModal.buyAmount": "Buy ₿ {btcAmount} Bitcoin",
  "buyBitcoinModal.btcPrice": "1 Bitcoin (BTC) equals {currencySymbol} {btcPrice}",
  "buyBitcoinModal.remainingTime": "remaining time {seconds} seconds",
  "buyBitcoinModal.nextButton": "NEXT",
  "buyBitcoinModal.termsAndConditions": "Terms and Conditions apply to this purchase.",
  "buyBitcoinModal.confirmPurchase": "CONFIRM PURCHASE",
  "buyBitcoinModal.confirmationText": "Confirm Purchase of ",
  "buyBitcoinModal.confirmationText2": " for ",
  "buyBitcoinModal.finalConfirmButton": "Confirm",
  "buyBitcoinModal.cancelButton": "Cancel",
  "buyBitcoinModal.transactionSuccess": "TRANSACTION CREATED",
  "buyBitcoinModal.transactionSuccessDetails": "BALANCE ALLOCATED IN THE ORDER",
  "buyBitcoinModal.transactionError": "ERROR",
  "buyBitcoinModal.transactionDetails": "Purchase Transaction",
  "buyBitcoinModal.transactionDetails2": " for ",
  "buyBitcoinModal.noTransactionDetails": "Transaction details not available.",
  "buyBitcoinModal.error.invalidAmount": "Enter a valid amount",
  "buyBitcoinModal.error.unsupportedCurrency": "Unsupported currency",
  "buyBitcoinModal.error.insufficientBalance": "Insufficient {currency} balance for this purchase",
  "buyBitcoinModal.error.apiError": "Error processing the purchase. Please try again.",
  "buyBitcoinModal.successOkButton": "Success",
  "buyBitcoinModal.error.minValue": "The amount must be at least R$ 1000 to proceed.",

  //Sell bitcoin modal
  "sellBitcoinModal.title": "SELL BITCOIN",
  "sellBitcoinModal.btcAmountLabel": "BTC amount to sell",
  "sellBitcoinModal.confirmHeader": "CONFIRM SALE",
  "sellBitcoinModal.successHeader": "TRANSACTION CREATED",
  "sellBitcoinModal.errorHeader": "ERROR",
  "sellBitcoinModal.error.invalidAmount": "Enter a valid BTC amount",
  "sellBitcoinModal.error.minimumAmount": "The amount must be at least 10.",
  "sellBitcoinModal.error.insufficientBalance": "Insufficient BTC balance for this sale",
  "sellBitcoinModal.error.apiError": "Error completing the sale. Please try again.",
  "sellBitcoinModal.error.notAuthenticated": "User not authenticated",
  "sellBitcoinModal.successMessage": "You successfully sold {btcAmount} BTC!",
  "sellBitcoinModal.transactionDetails": "Sale Transaction",
  "sellBitcoinModal.transactionDetailsAmount": "₿ {btcAmount} for R$ {brlAmount}",
  "sellBitcoinModal.exchangeRate": "1 Bitcoin (BTC) is equivalent to R$ {btcPriceInBRL}",
  "sellBitcoinModal.countdownText": "{countdown} seconds remaining",
  "sellBitcoinModal.confirmSaleText": "Confirm sale of ₿ {btcAmount} for R$ {brlAmount}",
  "sellBitcoinModal.terms": "Terms and Conditions apply to this sale.",
  "sellBitcoinModal.termsLink": "Terms and Conditions",
  "sellBitcoinModal.termsDescription": "Read the Terms and Conditions before completing the sale.",
  "sellBitcoinModal.buttons.next": "NEXT",
  "sellBitcoinModal.buttons.confirm": "Confirm",
  "sellBitcoinModal.buttons.cancel": "Cancel",
  "sellBitcoinModal.buttons.ok": "OK",
  "sellBitcoinModal.placeholder.btcAmount": "BTC amount",
  "sellBitcoinModal.transactionDetailsUnavailable": "Transaction details unavailable.",
  "sellBitcoinModal.confirmSaleTextPart1": "Confirm sale of ",
  "sellBitcoinModal.confirmSaleTextPart2": "for R$ {brlAmount}",
  "sellBitcoinModal.transactionDetails1": "Sale Transaction",
  "sellBitcoinModal.transactionDetails2": "for R$ {brlAmount}",
  "sellBitcoinModal.balanceAllocatedMessage": "The balance has been allocated to the order."
};
