import React from "react";
import Spacing from "../Spacing";
import HeroStyle2 from "../Hero/HeroStyle2";

import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Accordion from "../Accordion";
import PostCarousel from "../Slider/PostCarousel";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Award from "../Award";
import VideoModal from "../VideoModal";
import ServiceSlider from "../Slider/ServiceSlider";
import AboutStyle2 from "../About/AboutStyle2";
import FunFact from "../FunFact";

import { pageTitle } from "../../helpers/PageTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

const funfactData = [
  {
    title: "home.funFacts.invested.title",
    number: "home.funFacts.invested.number"
  },
  {
    title: "home.funFacts.projects.title",
    number: "home.funFacts.projects.number"
  },
  {
    title: "home.funFacts.investors.title",
    number: "home.funFacts.investors.number"
  },
  {
    title: "home.funFacts.return.title",
    number: "home.funFacts.return.number"
  }
];
const awardData = [
  {
    brand: "1",
    title: "home.awards.items.0.title",
    subTitle: 'home.awards.items.0.subTitle',
    awardImgUrl: "/images/creative-agency/award_img_1.svg",
  },
  {
    brand: "2",
    title: "home.awards.items.1.title",
    subTitle: 'home.awards.items.1.subTitle',
    awardImgUrl: "/images/creative-agency/award_img_2.svg",
  },
  {
    brand: "3",
    title: "home.awards.items.2.title",
    subTitle: 'home.awards.items.2.subTitle',
    awardImgUrl: "/images/creative-agency/award_img_3.svg",
  },
];
const testimonialData = [
  {
    text: "home.testimonials.items.0.text",
    avatarName: "Daniel Goldfarb",
    avatarDesignation: "home.testimonials.items.0.avatarDesignation",
  },
  {
    text: "home.testimonials.items.1.text",
    avatarName: "Gustavo Albuquerque",
    avatarDesignation: "home.testimonials.items.1.avatarDesignation",
  },
  {
    text: "home.testimonials.items.2.text",
    avatarName: "Avraham Goldstein",
    avatarDesignation: "home.testimonials.items.2.avatarDesignation",
  },
];

const postData = [
  {
    thumbnailSrc: "/section 7/img1.png",
    title: "Como evitar que o medo arruíne seu negócio artístico com confiança",
    date: "07 Mar 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img2.png",
    title: "Mente artística será ótima para criar qualquer coisa",
    date: "22 Abr 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img3.png",
    title: "IA assumirá todos os empregos humanos em poucos anos",
    date: "13 Mai 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img4.png",
    title: "Sua agência precisa substituir algumas pessoas com mente artística",
    date: "15 Mar 2023",
    url: "/blog/blog-details",
  },
];

const faqData = [
  {
    title: "home.faq.items.0.title",
    content: "home.faq.items.0.content"
  },
  {
    title: "home.faq.items.1.title",
    content: "home.faq.items.1.content"
  },
  {
    title: "home.faq.items.2.title",
    content: "home.faq.items.2.content"
  },
  {
    title: "home.faq.items.3.title",
    content: "home.faq.items.3.content"
  },
  {
    title: "home.faq.items.4.title",
    content: "home.faq.items.4.content"
  }
];

export default function MarketingAgencyPage({ darkMode }) {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  
  pageTitle(translate("home.pageTitle"));

  return (
    <>
      <HeroStyle2
        miniTitle={translate("home.hero.miniTitle")}
        title={translate("home.hero.title")}
        subTitle={translate("home.hero.subTitle")}
        thumbnailSrc="/img1.png"
        mikeIcon={
          darkMode ? "/images/icons/mike_dark.svg" : "/images/icons/mike.svg"
        }
      />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="140" md="75" />
        <SectionHeading 
          title={translate("home.projects.title")} 
          subTitle={translate("home.projects.subTitle")} 
        />
        <Spacing lg="85" md="45" />
        <ServiceSlider />
        <button
          style={{ marginInline: "auto", marginTop: "2rem" }}
          className="cs_btn cs_style_1"
          onClick={() => navigate("/projects")}
        >
          {translate("home.projects.viewAll")}
        </button>
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/section 3/img22.png"
          thumbnailSrc2="/section 3/img11.png"
          title={translate("home.about.title")}
          subTitle={translate("home.about.subTitle")}
          featureList={[
            translate("home.about.features.0"),
            translate("home.about.features.1"),
            translate("home.about.features.2"),
            translate("home.about.features.3"),
          ]}
          btnText={translate("home.about.btnText")}
          btnUrl="/register"
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>
      <div className="container">
        <Spacing lg="126" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/aSCoGaVvlMs?si=GfWzctiSzsEuHCS"
          bgUrl="/section 4/video.jpg"
          title={translate("home.video.title")}
        />
      </div>
      <TestimonialSlider
        layeredImages={[
          "/section 5/img5.png",
          "/section 5/img1.png",
          "/section 5/img3.png",
          "/section 5/img4.png",
          "/section 5/img2.png",
        ]}
        data={testimonialData}
      />
      <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title={translate("home.awards.title")}
            subTitle={translate("home.awards.subTitle")}
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title={translate("home.blog.title")}
            subTitle={translate("home.blog.subTitle")}
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading 
            title={translate("home.faq.title")} 
            subTitle={translate("home.faq.subTitle")} 
          />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title={translate("home.cta.title")}
            btnText={translate("home.cta.btnText")}
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
    </>
  );
}
