import React from "react";
import { useTranslation } from "../../hooks/useTranslation";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import AboutStyle4 from "../About/AboutStyle4";
import FunFact from "../FunFact";
import IconBoxStyle6 from "../IconBox/IconBoxStyle6";
import SectionHeadingStyle5 from "../SectionHeading/SectionHeadingStyle5";
import { pageTitle } from "../../helpers/PageTitle";

const funfactData = [
  { title: "aboutPage.invested", number: "72M" },
  { title: "aboutPage.launchedProjects", number: "65" },
  { title: "aboutPage.investors", number: "1k" },
  { title: "aboutPage.annualReturn", number: "23%" },
];

export default function AboutPage({ darkMode }) {
  const { translate } = useTranslation();
  pageTitle("About");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("aboutPage.mainTitle")}
        subTitle={translate("aboutPage.subTitle")}
        variant="text-center"
        shape="shape_1"
      />
      <Spacing lg="75" md="60" />
      <AboutStyle4
        thumbnailSrc="/img1.png"
        miniTitle={translate("aboutPage.companyInfo")}
        title={translate("aboutPage.investmentPlatform")}
        subTitle={translate("aboutPage.teamDescription")}
        btnText={translate("aboutPage.learnMore")}
        btnUrl="/"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData.map(item => ({ ...item, title: translate(item.title) }))} />
      </div>
      <Spacing lg="125" md="70" />
      <section className="cs_primary_bg">
        <Spacing lg="100" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title={translate("aboutPage.howItWorks")} />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title={translate("aboutPage.research")}
                  subTitle={translate("aboutPage.researchDesc")}
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title={translate("aboutPage.evaluation")}
                  subTitle={translate("aboutPage.evaluationDesc")}
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title={translate("aboutPage.investment")}
                  subTitle={translate("aboutPage.investmentDesc")}
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
    </>
  );
}