import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

export default function IconBox({
  iconSrc,
  title,
  description,
  btnText,
  btnUrl,
  shapeClass,
  progress,
  projectId,
  currency,
  type,
}) {
  const { translate } = useTranslation();


  return (
    <div
      style={{
        height: "550px",
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr 3fr 1fr",
        padding: "2rem",
      }}
      className="cs_iconbox cs_style_1 cs_primary_bg cs_radius_15"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          src={iconSrc}
          alt="Icon"
          style={{ width: "90px", height: "90px", borderRadius: "100%" }}
        />
        <div style={{ display: "flex", gap: "10px" }}>
          <span
            style={{
              border: "2px solid #c89c5c",
              background: "transparent",
              padding: "5px 10px",
              borderRadius: "10px",
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {`${currency}`}
          </span>
          <span
            style={{
              border: "2px solid #c89c5c",
              background: "transparent",
              padding: "5px 10px",
              borderRadius: "10px",
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {`${type}`}
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0.5rem",
        }}
      >
        <h2
          style={{
            fontSize: "1.8rem",
            margin: 0,
          }}
          className="cs_iconbox_title cs_fs_29 cs_white_color"
        >
          {title}
        </h2>
      </div>

      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "45px",
            borderRadius: "10px",
            background: "#FFF",
            position: "relative",
          }}
        >
          <div
            style={{
              width: Number(progress).toFixed(0) == 0 ? "0%" : `${progress}%`,
              height: "100%",
              background: "#c89c5c",
              borderRadius: "10px",
              position: "absolute",
              left: 0,
              top: 0,
              transition: "width 0.5s ease",
            }}
          ></div>
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              color: Number(progress) > 10 ? "#FFF" : "#222221",
              fontWeight: "bold",
            }}
          >
            {progress}%
          </span>
        </div>
      </div>

      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <p
          className="cs_iconbox_subtitle"
          style={{
            margin: 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "6",
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          padding: "0.5rem",
        }}
      >
        <Link
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={`cs_btn cs_style_1 cs_btn_accent`}
          to={`/project/${projectId}`}
        >
          {btnText || translate("home.serviceSlider.IconBox.buttonLink")}
        </Link>
      </div>
    </div>
  );
}
