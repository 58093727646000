import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import LoaderComponent from "../../Loader/Loader";
import { useTranslation } from "../../../hooks/useTranslation";

export default function UserWallet() {
  const { translate } = useTranslation();
  const [shares, setShares] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchShares = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error(translate("userWallet.errorLogin"));
          return;
        }

        const response = await fetch(
          `https://getusershares-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setShares(data.shares);
      } catch (error) {
        console.error("Erro ao buscar ações:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchShares();
  }, [currentPage]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <h2>{translate("userWallet.title")}</h2>
          <div className="table-responsive">
            <table className="cs_cart_table">
              <thead>
                <tr>
                  <th>{translate("userWallet.project")}</th>
                  <th style={{ textAlign: "center" }}>
                    {translate("userWallet.shares")}
                  </th>
                  <th></th>
                  <th style={{ textAlign: "center" }}>
                    {translate("userWallet.originalValue")}
                  </th>
                  <th></th>
                  <th style={{ textAlign: "center" }}>
                    {translate("userWallet.totalValue")}
                  </th>
                  <th style={{ textAlign: "center" }}>
                    {translate("userWallet.action")}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan="7"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "40px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                ) : shares.length === 0 ? (
                  <tr>
                    <td
                      colSpan="7"
                      style={{
                        textAlign: "center",
                        padding: "50px 0",
                        fontSize: "1.2rem",
                        color: "#666"
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h3>{translate("userWallet.empty")}</h3>
                      </div>
                    </td>
                  </tr>
                ) : (
                  shares.map((share) => (
                    <tr key={share.projectId}>
                      <td>
                        <div className="cs_cart_table_media">
                          <img src={share.projectImage} alt="Thumb" />
                          <h3>{share.projectTitle}</h3>
                        </div>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {share.numberOfShares }
                      </td>
                      <td></td>
                      <td style={{ textAlign: "center" }}>
                        {share.currency === 'BTC' 
                          ? `₿ ${(parseFloat(share.sharePrice) / 100000000)}`
                          : share.currency === 'USDT' 
                          ? `$ ${(share.sharePrice).toFixed(2)}`
                          : `R$ ${(share.sharePrice / 100).toFixed(2)}`}
                      </td>
                      <td></td>
                      <td style={{ textAlign: "center" }}>
                        {share.currency === 'BTC' 
                          ? `₿ ${(parseFloat(share.sharePrice * share.numberOfShares || 0) / 100000000)}`
                          : share.currency === 'USDT' 
                          ? `$ ${((share.numberOfShares * share.sharePrice)).toFixed(2)}`
                          : `R$ ${((share.numberOfShares * share.sharePrice) / 100).toFixed(2)}`}
                      </td>
                      <td style={{ padding: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "20px",
                          }}
                        >
                          <button
                            style={{
                              background: "#3498db",
                              padding: "15px 30px",
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {translate("userWallet.sellButton")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
