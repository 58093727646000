import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTranslation } from "../../../hooks/useTranslation";
import LoaderComponent from "../../Loader/Loader";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../Spacing";
import { getUserBalance } from "../investments/GetUserBalance";

const DepositAdmin = ({ darkMode }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        console.log("Saldo do usuário:", userBalance);
      }
    };

    fetchBalance();
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingActions, setLoadingActions] = useState({});

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error(translate("depositAdmin.loadingTransactions"));
          return;
        }

        const response = await fetch(
          `https://getdepositslist-qj6yfdqbiq-uc.a.run.app`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log("Resposta da API:", data);
        const pendingDeposits = data.deposits.filter(
          (deposit) => deposit.status === "pending"
        );
        setDeposits(pendingDeposits);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const formatAmount = (amount, type) => {
    if (type === "BTC") {
      const btcAmount = amount / 100000000;
      return `₿${btcAmount.toFixed(8)}`;
    } else {
      const fiatAmount = amount / 100;
      return `R$${fiatAmount.toFixed(2)}`;
    }
  };

  const handleConfirm = async (depositId) => {
    setLoadingActions((prev) => ({ ...prev, [`confirm-${depositId}`]: true }));
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error(translate("depositAdmin.confirmError"));
        return;
      }

      const response = await fetch(
        `https://adminconfirmuserdeposit-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            depositId: depositId,
            action: "confirm",
          }),
        }
      );

      console.log("Resposta da API:", response);

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || translate("depositAdmin.confirmSuccess"));
        setDeposits((prevDeposits) =>
          prevDeposits.filter((deposit) => deposit.id !== depositId)
        );
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || translate("depositAdmin.confirmError"));
      }
    } catch (error) {
      console.error("Erro ao confirmar o depósito:", error);
      toast.error(translate("depositAdmin.confirmError"));
    } finally {
      setLoadingActions((prev) => ({
        ...prev,
        [`confirm-${depositId}`]: false,
      }));
    }
  };

  const handleReject = async (depositId) => {
    setLoadingActions((prev) => ({ ...prev, [`reject-${depositId}`]: true }));
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error(translate("depositAdmin.rejectError"));
        return;
      }

      const response = await fetch(
        `https://adminconfirmuserdeposit-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            depositId: depositId,
            action: "reject",
          }),
        }
      );

      console.log("Resposta da API:", response);

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || translate("depositAdmin.rejectSuccess"));
        setDeposits((prevDeposits) =>
          prevDeposits.filter((deposit) => deposit.id !== depositId)
        );
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || translate("depositAdmin.rejectError"));
      }
    } catch (error) {
      console.error("Erro ao reprovar o depósito:", error);
      toast.error(translate("depositAdmin.rejectError"));
    } finally {
      setLoadingActions((prev) => ({
        ...prev,
        [`reject-${depositId}`]: false,
      }));
    }
  };

  const handleDownload = (url) => {
    if (url !== "none") {
      window.open(url, "_blank");
    } else {
      toast.error(translate("depositAdmin.noReceipt"));
    }
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("depositAdmin.title")}
        subTitle={translate("depositAdmin.subtitle")}
        variant="text-left"
        href="/deposit-admin"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.email")}</th>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.cpf")}</th>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.date")}</th>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.amount")}</th>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.receipt")}</th>
                    <th style={{ width: "20%", textAlign: "center" }}>{translate("depositAdmin.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        rowSpan="10"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <LoaderComponent />
                      </td>
                    </tr>
                  ) : (
                    deposits.map((deposit, index) => (
                      <tr
                        key={deposit.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {deposit.userEmail}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {deposit.userCpf}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatDate(deposit.timestamp)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatAmount(deposit.amount, deposit.type)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          <button
                            onClick={() => handleDownload(deposit.receipt)}
                            disabled={deposit.receipt === "none"}
                            style={{
                              background:
                                deposit.receipt === "none"
                                  ? "#a9a9a9"
                                  : "#007bff",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              cursor:
                                deposit.receipt === "none"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Ver Comprovante
                          </button>
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          <button
                            onClick={() => handleConfirm(deposit.id)}
                            disabled={loadingActions[`confirm-${deposit.id}`]}
                            style={{
                              background: loadingActions[
                                `confirm-${deposit.id}`
                              ]
                                ? "#a9a9a9"
                                : "#11C160",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              marginBottom: "10px",
                              cursor: loadingActions[`confirm-${deposit.id}`]
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            {loadingActions[`confirm-${deposit.id}`] ? (
                              <div
                                style={{
                                  marginInline: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <LoaderComponent
                                  size={20}
                                  borderThickness={2}
                                  color="#FFF"
                                />
                              </div>
                            ) : (
                              "Confirmar"
                            )}
                          </button>
                          <button
                            onClick={() => handleReject(deposit.id)}
                            disabled={loadingActions[`reject-${deposit.id}`]}
                            style={{
                              background: loadingActions[`reject-${deposit.id}`]
                                ? "#a9a9a9"
                                : "#E83238",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              cursor: loadingActions[`reject-${deposit.id}`]
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            {loadingActions[`reject-${deposit.id}`] ? (
                              <div
                                style={{
                                  marginInline: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <LoaderComponent
                                  size={20}
                                  borderThickness={2}
                                  color="#FFF"
                                />
                              </div>
                            ) : (
                              "Reprovar"
                            )}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("depositAdmin.previous")}
              </button>
              <span>
                {translate("depositAdmin.page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <button
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("depositAdmin.next")}
              </button>
            </div>

            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositAdmin;
