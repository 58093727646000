import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Spacing";
import Showdown from "showdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InvestModal from "../../Modals/InvestModal";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getUserBalance } from "../investments/GetUserBalance";
import Documents from "./Documents";
import { UserContext } from "../../../context/UserContext";
import LoaderComponent from "../../Loader/Loader";
import { useTranslation } from "../../../hooks/useTranslation";

export default function InvestInfo({ ProjectData }) {
  const { accessLevel } = useContext(UserContext);

  const [markdownContent, setMarkdownContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isInvestModal, setisInvestModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [sharesToSimulate, setSharesToSimulate] = useState(1);
  const navigate = useNavigate();
  const { language, translate } = useTranslation();

  const handleInvestModal = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setisInvestModal(!isInvestModal);
    } else {
      setIsLoginModal(true);
    }
  };

  const handleLoginModalClose = () => {
    setIsLoginModal(false);
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 1) {
      setSharesToSimulate(value);
    }
  };

  const handleShareChange = (increment) => {
    const newValue = sharesToSimulate + increment;
    if (newValue >= 1) {
      setSharesToSimulate(newValue);
    }
  };

  const handleEditProject = () => {
    navigate(`/edit-project/${ProjectData.projectId}`, {
      state: {
        projectId: ProjectData.projectId,
        title: ProjectData.title,
        description: ProjectData.description,
        descriptionEN: ProjectData.descriptionEN,
        startDate: ProjectData.startDate,
        endDate: ProjectData.endDate,
        videoUrl: ProjectData.videoUrl,
      },
    });
  };

  useEffect(() => {
    const fetchMarkdown = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-barzelbank.cloudfunctions.net/fetchFullProjectDescription?projectId=${ProjectData.projectId}&lang=${language}`
        );

        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }
        const text = await response.text();

        const converter = new Showdown.Converter({
          tables: true,
          simplifiedAutoLink: true,
          strikethrough: true,
          tasklists: true,
          emoji: true,
        });

        const markdown = converter.makeHtml(text);
        setMarkdownContent(markdown);
      } catch (error) {
        console.error("Erro ao buscar conteúdo markdown:", error);
        setMarkdownContent("Falha ao carregar descrição.");
      } finally {
        setIsLoading(false);
      }
    };

    if (ProjectData.projectId) {
      fetchMarkdown();
    }
  }, [ProjectData.projectId, language]);

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        setBalance(userBalance);
      }
    };

    fetchBalance();
  }, []);

  const participationPercentage = (
    (((ProjectData.soldShares + ProjectData.reservedShares) *
      ProjectData.sharePrice) /
      (ProjectData.totalShares * ProjectData.sharePrice)) *
    100
  ).toFixed(2);

  const isActiveLoading = !ProjectData?.title

  return (
    <>
      {isInvestModal && (
        <InvestModal
          close={handleInvestModal}
          price={ProjectData.sharePrice}
          projectId={ProjectData.projectId}
          projectName={ProjectData.title}
          currency={ProjectData.currency}
          balance={balance}
          sharesToSimulate={sharesToSimulate}
        />
      )}
      {isLoginModal && (
        <LoginModal
          close={handleLoginModalClose}
          redirect={handleLoginRedirect}
        />
      )}
      <div className="cs_about cs_style_1">
        <div className="container">
        {isActiveLoading ? 
          <div
            style={{
              width: "100%",
              height: "570px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderComponent />
          </div>
          : (
            <div className="row align-items-top cs_gap_y_40">
              <div className="col-lg-6">
                <Image1>
                  <div
                    style={{
                      height: "650px",
                      borderRadius: "10px",
                      backgroundImage: `url(${ProjectData.thumbnailUrl})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </Image1>
                {isLoading ? (
                  <>
                    <div style={{ marginBlock: "1rem" }}>
                      <Skeleton
                        height={50}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                      <Skeleton
                        height={50}
                        style={{ width: "75%" }}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                    </div>
                    <div style={{ marginBlock: "1rem" }}>
                      <Skeleton
                        count={6}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                      <Skeleton
                        style={{ width: "75%" }}
                        count={2}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                    </div>
                    <div style={{ marginBlock: "1rem" }}>
                      <Skeleton
                        count={3}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                      <Skeleton
                        style={{ width: "40%" }}
                        count={1}
                        baseColor="#333332"
                        highlightColor="#222221"
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="markdown-content"
                    dangerouslySetInnerHTML={{ __html: markdownContent }}
                    style={{
                      color: "#fff",
                      padding: "20px",
                      lineHeight: "1.6",
                    }}
                  />
                )}
              </div>

              <StyledDiv className="col-lg-6">
                <div className="cs_section_heading cs_style_1">
                  <Image2>
                    <div
                      style={{
                        height: "650px",
                        borderRadius: "10px",
                        backgroundImage: `url(${ProjectData.thumbnailUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginBottom: "2rem",
                      }}
                    />
                  </Image2>
                  <p className="cs_section_subtitle cs_accent_color cs_fs_18 mb-0">
                    {translate("investInfo.project")}
                  </p>
                  <Spacing lg="10" md="5" />
                  <h2 className="cs_section_title cs_fs_50 mb-0">
                    {ProjectData.title}
                  </h2>
                  <Spacing lg="24" md="24" />

                  <h4 className="cs_section_title cs_fs_30 mb-0">
                    {ProjectData.currency === "BTC"
                      ? `BTC ${(
                          (ProjectData.soldShares * ProjectData.sharePrice) /
                          100000000
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 8,
                          maximumFractionDigits: 8,
                        })}`
                      : `${ProjectData.currency === "USDT" ? "USDT" : "BRL"} ${(
                          (ProjectData.soldShares * ProjectData.sharePrice) /
                          100
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}{" "}
                    {translate("investInfo.raised")}{" "}
                    {ProjectData.currency === "BTC"
                      ? `BTC ${(
                          (ProjectData.totalShares * ProjectData.sharePrice) /
                          100000000
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 8,
                          maximumFractionDigits: 8,
                        })}`
                      : `${ProjectData.currency === "USDT" ? "USDT" : "BRL"} ${(
                          (ProjectData.totalShares * ProjectData.sharePrice) /
                          100
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                  </h4>
                  <Spacing lg="10" md="5" />
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginBottom: "2rem",
                      borderRadius: "10px",
                      background: "#FFF",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: `${participationPercentage}%`,
                        height: "100%",
                        background: "#c89c5c",
                        borderRadius: "10px",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        transition: "width 0.5s ease",
                      }}
                    ></div>
                    <span
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: participationPercentage > 10 ? "#FFF" : "#222221",
                        fontWeight: "bold",
                      }}
                    >
                      {participationPercentage}%
                    </span>
                  </div>

                  <h4
                    style={{ fontWeight: "normal" }}
                    className="cs_section_title cs_fs_30 mb-0"
                  >
                    {translate("investInfo.remainingShares")}: {ProjectData.totalShares - ProjectData.soldShares}
                  </h4>
                  <Spacing lg="10" md="5" />
                  <Spacing lg="10" md="5" />
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}
                  >
                    <button
                      style={{
                        borderRadius: "5px",
                        height: "50px",
                        padding: "25px 60px",
                        fontSize: "1.15rem",
                        fontWeight: "normal",
                      }}
                      onClick={() => handleInvestModal()}
                      className={`cs_btn cs_style_1 cs_btn_accent`}
                    >
                      {translate("investInfo.invest")}
                    </button>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <button
                        onClick={() => handleShareChange(-1)}
                        style={{
                          background: "#c89c5c",
                          border: "none",
                          color: "white",
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                        }}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={sharesToSimulate}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "5px",
                          height: "50px",
                          width: "120px",
                          padding: "0 10px",
                          fontSize: "1.15rem",
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#333332",
                          border: "1px solid #c89c5c",
                          MozAppearance: "textfield",
                        }}
                        min="1"
                      />
                      <style>
                        {`
                          /* Remover controles padrão para navegadores WebKit */
                          input[type=number]::-webkit-inner-spin-button, 
                          input[type=number]::-webkit-outer-spin-button { 
                            -webkit-appearance: none; 
                            margin: 0; 
                          }
                        `}
                      </style>
                      <button
                        onClick={() => handleShareChange(1)}
                        style={{
                          background: "#c89c5c",
                          border: "none",
                          color: "white",
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      color: "#c89c5c",
                      fontSize: "1.1rem",
                      marginTop: "10px",
                    }}
                  >
                    {translate("investInfo.monthlyReturn")}:{" "}
                    {ProjectData.currency === "BTC"
                      ? `BTC ${(
                          (sharesToSimulate *
                            (ProjectData.returnPerShare / 10000) *
                            ProjectData.sharePrice) /
                          100000000
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 8,
                          maximumFractionDigits: 8,
                        })}`
                      : `${ProjectData.currency === "USDT" ? "USDT" : "R$"} ${(
                          (sharesToSimulate *
                            (ProjectData.returnPerShare / 10000) *
                            ProjectData.sharePrice) /
                          100
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}{" "}
                    {translate("investInfo.investing")}{" "}
                    {ProjectData.currency === "BTC"
                      ? `BTC ${(
                          (sharesToSimulate * ProjectData.sharePrice) /
                          100000000
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 8,
                          maximumFractionDigits: 8,
                        })}`
                      : `${ProjectData.currency === "USDT" ? "USDT" : "R$"} ${(
                          (sharesToSimulate * ProjectData.sharePrice) /
                          100
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                  </div>

                  <Spacing lg="10" md="5" />
                  <div style={{ color: "white", fontSize: "1.1rem" }}>
                    <p>
                      {translate("investInfo.minInvestment")}:{" "}
                      {ProjectData.currency === "BTC"
                        ? `BTC ${(
                            ProjectData.sharePrice / 100000000
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 8,
                            maximumFractionDigits: 8,
                          })}`
                        : `${ProjectData.currency === "USDT" ? "USDT" : "R$"} ${(
                            ProjectData.sharePrice / 100
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                    </p>
                    <p>
                      {translate("investInfo.shareValue")}:{" "}
                      {ProjectData.currency === "BTC"
                        ? `BTC ${(
                            ProjectData.sharePrice / 100000000
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 8,
                            maximumFractionDigits: 8,
                          })}`
                        : `${ProjectData.currency === "USDT" ? "USDT" : "R$"} ${(
                            ProjectData.sharePrice / 100
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                    </p>
                    <p>
                      {translate("investInfo.monthlyProfit")}:{" "}
                      {ProjectData.currency === "BTC"
                        ? `BTC ${(
                            ((ProjectData.returnPerShare / 10000) *
                              ProjectData.sharePrice) /
                            100000000
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 8,
                            maximumFractionDigits: 8,
                          })}`
                        : `${ProjectData.currency === "USDT" ? "USDT" : "R$"} ${(
                            ((ProjectData.returnPerShare / 10000) *
                              ProjectData.sharePrice) /
                            100
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                    </p>
                    <p>{translate("investInfo.totalSold")}: {participationPercentage}%</p>
                  </div>
                  {accessLevel === 3 && (
                    <>
                      <button
                        style={{
                          borderRadius: "5px",
                          height: "50px",
                          padding: "25px 60px",
                          fontSize: "1.15rem",
                          fontWeight: "normal",
                        }}
                        onClick={handleEditProject}
                        className={"cs_btn cs_style_1 cs_btn_accent"}
                      >
                        Editar
                      </button>
                    </>
                  )}
                  <Spacing lg="10" md="5" />
                  <h3 style={{ color: "#BF9A5D", fontSize: "2rem", margin: "0" }}>
                    {translate("investInfo.description")}
                  </h3>
                  <p style={{ color: "#fff" }}>{language === "pt" ? ProjectData?.description : ProjectData?.descriptionEN}</p>
                  <Spacing lg="24" md="24" />
                  <Documents ProjectData={ProjectData} />
                </div>
              </StyledDiv>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function LoginModal({ close, redirect }) {
  return (
    <>
      <Overlay onClick={close} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={close}
        >
          <FaTimes size={15} color="#fff" />
        </div>
        <Header>
          Por favor, faça login para <span style={{ color: "" }}>investir</span>
        </Header>
        <Button onClick={redirect}>OK</Button>
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  padding: 0 1rem;

  @media (max-width: 990px) {
    order: -1;
  }
`;

const Image1 = styled.div`
  @media (max-width: 990px) {
    display: none;
  }
`;

const Image2 = styled.div`
  display: none;

  @media (max-width: 990px) {
    display: block;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 350px;
  width: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #121212;
  z-index: 901;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: white;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  width: 90%;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  // margin-top: auto;
  width: 90%;
  align-self: center;

  &:hover {
    background-color: #8c7444;
  }
`;
