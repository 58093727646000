import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';

export default function Award({ data }) {
  const { translate } = useTranslation();
  
  return (
    <div className="cs_awaard_1_list">
      {data?.map((item, index) => (
        <div className="cs_awaard cs_style_1" key={index}>
          <h3 className="cs_awaard_brand mb-0 cs_fs_29 cs_semibold cs_accent_color">
            {item.brand}
          </h3>
          <div className="cs_awaard_info">
            <p className="cs_awaard_subtitle mb-0">{translate(item.subTitle)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
