import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

const addressDataList = [
  "footer.address",
  "footer.phone",
  "footer.email",
];

const serviceMenuList = [
  {
    title: "footer.services.internationalInvestments",
    href: "/contact",
  },
  {
    title: "footer.services.tokenizationRWA",
    href: "/contact",
  },
  {
    title: "footer.services.bitcoinOTC",
    href: "/contact",
  },
];

const LinksMenuList = [
  {
    title: "footer.links.home",
    href: "/",
  },
  {
    title: "footer.links.about",
    href: "/about",
  },
  {
    title: "footer.links.contact",
    href: "/contact",
  },
  {
    title: "footer.links.terms-and-conditions",
    href: "/terms-and-conditions",
  },
];

const policyMenuList = [
  {
    title: "footer.policy.terms",
    href: "/",
  },
  {
    title: "footer.policy.privacy",
    href: "/",
  },
];

const socialBtnList = [
  {
    icon: "fa6-brands:linkedin-in",
    href: "/",
  },
  {
    icon: "fa6-brands:youtube",
    href: "/",
  },
  {
    icon: "fa6-brands:instagram",
    href: "/",
  },
];

export default function Footer() {
  const { translate } = useTranslation();

  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{
        backgroundImage: "url(/images/footer_bg.jpeg)",
        marginTop: "100px",
      }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img
                    style={{ width: "200px" }}
                    src="/barzel-logo.png"
                    alt="Logo"
                  />
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>{translate(item)}</li>
                  ))}
                </ul>
              </div>
              <img src="/CVM.png" alt="Logo" style={{ width: "150px" }} />
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">{translate("footer.services.title")}</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {serviceMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{translate(item.title)}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">{translate("footer.links.title")}</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {LinksMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{translate(item.title)}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">{translate("footer.newsletter.title")}</h2>
                <div className="cs_newsletter cs_style_1">
                  <div className="cs_newsletter_text">
                    {translate("footer.newsletter.text")}
                  </div>
                  <form action="#" className="cs_newsletter_form">
                    <input
                      type="email"
                      className="cs_newsletter_input"
                      placeholder={translate("footer.newsletter.placeholder")}
                    />
                    <button className="cs_btn cs_style_1">
                      {translate("footer.newsletter.button")}
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="cs_bottom_footer">
            <div className="cs_bottom_footer_left">
              <div className="cs_social_btns cs_style_1">
                {socialBtnList.map((item, index) => (
                  <Link to={item.href} className="cs_center" key={index}>
                    <Icon icon={item.icon} />
                  </Link>
                ))}
              </div>
            </div>
            <div className="cs_copyright">{translate("footer.copyright")}</div>
            <div className="cs_bottom_footer_right">
              <ul className="cs_footer_links cs_mp0">
                {policyMenuList.map((item, index) => (
                  <li key={index}>
                    <Link to={item.href}>{translate(item.title)}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
