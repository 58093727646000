import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import IconBox from "../IconBox/index.jsx";
import { useTranslation } from "../../hooks/useTranslation.js";

export default function ServiceSlider() {
  const [projects, setProjects] = useState([]);
  const { language } = useTranslation();

  const fetchProjects = useCallback(async () => {
    try {
      const response = await fetch(
        "https://listcrowdfundprojects-qj6yfdqbiq-uc.a.run.app",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setProjects(data.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={24}
        speed={800}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          500: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 3,
          },
          1750: {
            slidesPerView: 4,
          },
        }}
      >
        {projects?.map((project, index) => (
          <SwiperSlide key={project.id}>
            <IconBox
              projectId={project.id}
              iconSrc={project.imageUrl}
              title={project.title}
              currency={project.currency}
              type={project.type}
              subtitle={`Meta: R$ ${(
                project.totalShares * project.sharePrice
              ).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`}
              progress={
                (
                  (((project.soldShares + project.reservedShares) *
                    project.sharePrice) /
                    (project.totalShares * project.sharePrice)) *
                  100
                ).toFixed(2) || 0
              }
              description={language === 'pt' ? project?.description : project?.descriptionEN}
              shapeClass={`cs_shape_${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
