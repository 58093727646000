import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import LoaderComponent from "../../Loader/Loader";
import { toast } from "react-hot-toast";
import { getUserBalance } from "../investments/GetUserBalance";
import { useTranslation } from "../../../hooks/useTranslation";

const Transactions = ({ darkMode }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
    };

    fetchBalance();
  }, []);

  // Obtém página da URL ou usa 1 como padrão
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [currencyFilter, setCurrencyFilter] = useState('ALL');
  const [typeFilter, setTypeFilter] = useState('ALL');

  // Atualiza URL quando a página muda
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error(translate("transactions.errorNotLoggedIn"));
          return;
        }

        const response = await fetch(
          `https://getusertransactions-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setTransactions(data.transactions);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const formatTime = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleTimeString();
  };

  const formatAmount = (amount, type, currency = "BRL") => {
    const effectiveCurrency = currency || "BRL";
    const formattedAmount = (() => {
      switch (effectiveCurrency.toUpperCase()) {
        case "BRL":
        case "USDT":
          return (Math.abs(amount) / 100).toFixed(2);
        case "BTC":
          return (Math.abs(amount) / 100000000).toFixed(8);
        default:
          return Math.abs(amount);
      }
    })();

    const currencySymbol =
      {
        BRL: "R$",
        USDT: "USDT",
        BTC: "₿",
      }[effectiveCurrency.toUpperCase()] || "";

    const sign =
      type.toLowerCase() === "deposit" ||
      type.toLowerCase() === "earning" ||
      type.toLowerCase() === "commission"
        ? "+"
        : "-";

    return `${sign} ${currencySymbol} ${formattedAmount}`;
  };

  return (
    <>
      <Spacing lg="100" md="80" />
        <SectionHeadingStyle3
          title={translate("transactions.title")}
          subTitle="suas"
          variant="text-left"
          href="/transactions"
        />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
          <div className="cs_currency_filter" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px', gap: '10px'}}>
            <select 
              value={currencyFilter}
              onChange={(e) => setCurrencyFilter(e.target.value)}
              style={{
                padding: '8px 15px',
                borderRadius: '5px',
                backgroundColor: '#242424',
                color: '#fff',
                border: '2px solid #BF9A5D',
                cursor: 'pointer'
              }}
            >
              <option value="ALL">{translate("transactions.all")}</option>
              <option value="BRL">BRL</option>
              <option value="BTC">BTC</option>
              <option value="USDT">USDT</option>
            </select>

            <select 
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              style={{
                padding: '8px 15px',
                borderRadius: '5px',
                backgroundColor: '#242424',
                color: '#fff',
                border: '2px solid #BF9A5D',
                cursor: 'pointer'
              }}
            >
              <option value="ALL">{translate("transactions.all")}</option>
              <option value="deposit">{translate("transactions.deposit")}</option>
              <option value="withdraw">{translate("transactions.withdraw")}</option>
              <option value="spent">{translate("transactions.spent")}</option>
              <option value="earning">{translate("transactions.earning")}</option>
              <option value="commission">{translate("transactions.commission")}</option>
            </select>
          </div>
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th>{translate("transactions.id")}</th>
                    <th>{translate("transactions.date")}</th>
                    <th>{translate("transactions.time")}</th>
                    <th>{translate("transactions.currency")}</th>
                    <th>{translate("transactions.type")}</th>
                    <th>{translate("transactions.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : transactions.filter(transaction => 
                    (currencyFilter === 'ALL' ? true : (transaction.currency || 'BRL') === currencyFilter) &&
                    (typeFilter === 'ALL' ? true : transaction.type.toLowerCase() === typeFilter.toLowerCase())
                  ).length === 0 ? (
                    <tr>
                      <td
                        colSpan="6"
                        style={{
                          textAlign: "center",
                          padding: "50px 0",
                          fontSize: "1.2rem",
                          color: "#666"
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "450px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h3>
                            {currencyFilter === 'ALL' && typeFilter === 'ALL'
                              ? translate("transactions.noTransactions")
                              : currencyFilter === 'ALL'
                              ? translate("transactions.noTransactionsType")
                              : typeFilter === 'ALL'
                              ? translate("transactions.noTransactionsCurrency")
                              : translate("transactions.noTransactionsType")}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    transactions
                      .filter(transaction => 
                        (currencyFilter === 'ALL' ? true : (transaction.currency || 'BRL') === currencyFilter) &&
                        (typeFilter === 'ALL' ? true : transaction.type.toLowerCase() === typeFilter.toLowerCase())
                      )
                      .map((transaction, index) => (
                        <tr
                          key={transaction.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "transparent" : "#242424",
                          }}
                        >
                          <td>{transaction.id}</td>
                          <td>{formatDate(transaction.timestamp)}</td>
                          <td>{formatTime(transaction.timestamp)}</td>
                          <td>{transaction.currency || "BRL"}</td>
                          <td>
                            {transaction.type === "deposit"
                              ? translate("transactions.deposit")
                              : transaction.type === "withdraw"
                              ? translate("transactions.withdraw")
                              : transaction.type === "spent"
                              ? translate("transactions.spent")
                              : transaction.type === "earning"
                              ? translate("transactions.earning")
                              : transaction.type === "commission"
                              ? translate("transactions.commission")
                              : transaction.type}
                          </td>
                          <td
                            style={{
                              color:
                                transaction.type.toLowerCase() === "deposit"
                                  ? "#009A00"
                                  : transaction.type.toLowerCase() === "withdraw"
                                  ? "#E40000"
                                  : transaction.type.toLowerCase() === "spent"
                                  ? "#14FFFF"
                                  : transaction.type.toLowerCase() === "earning"
                                  ? "#00FF00"
                                  : transaction.type.toLowerCase() ===
                                    "commission"
                                  ? "#00FF00"
                                  : "inherit",
                              fontWeight: "bold",
                            }}
                          >
                            {formatAmount(
                              transaction.amount,
                              transaction.type,
                              transaction.currency
                            )}
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Paginação */}
            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("transactions.previous")}
              </button>
              <span>
                {translate("transactions.page")} {currentPage} {translate("transactions.of")} {totalPages}
              </span>
              <button
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("transactions.next")}
              </button>
            </div>

            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
