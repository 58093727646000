import { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [userBalance, setUserBalance] = useState({
    brl: 0,
    btc: 0,
    usdt: 0,
    shares: 0,
    pending: 0,
  });
  const [isLogged, setIsLogged] = useState(false);
  const [accessLevel, setAccessLevel] = useState(0);
  const [updateHeader, setUpdateHeader] = useState(0);
  const [profilePicture, setProfilePicture] = useState(
    process.env.PUBLIC_URL + "/perfil.png"
  );
  const [cryptoPrices, setCryptoPrices] = useState({
    btc: 0,
    usdt: 0,
  });

  const updateUserBalance = (newBalance) => {
    setUserBalance((prev) => ({
      ...prev,
      ...newBalance,
    }));
  };

  const updateLoginStatus = (status) => {
    setIsLogged(status);
  };

  const updateCryptoPrices = (prices) => {
    setCryptoPrices(prices);
  };

  return (
    <UserContext.Provider
      value={{
        userBalance,
        updateUserBalance,
        isLogged,
        updateLoginStatus,
        setAccessLevel,
        accessLevel,
        setUpdateHeader,
        updateHeader,
        profilePicture,
        setProfilePicture,
        cryptoPrices,
        updateCryptoPrices,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
