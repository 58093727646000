import React from "react";
import Spacing from "../Spacing";
import { pageTitle } from "../../helpers/PageTitle";
import styled from "styled-components";
import { useTranslation } from "../../hooks/useTranslation";

const TermsContainer = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
`;

const SectionTitle = styled.h3`
  color: rgb(255, 255, 255);
  margin-top: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  color: #fff;
`;

export default function TermsPage() {
  pageTitle("Terms and Conditions");

  const { translate } = useTranslation();

  return (
    <>
      <Spacing lg="70" md="40" />
      <Spacing lg="70" md="40" />
      <div className="container">
        <TermsContainer>
          <h2>{translate("terms.title")}</h2>
          <Paragraph>{translate("terms.intro")}</Paragraph>
          <Paragraph>{translate("terms.essential")}</Paragraph>

          <SectionTitle>{translate("terms.section1")}</SectionTitle>
          <Paragraph>
            <strong>{translate("terms.platform")}</strong>{" "}
            {translate("terms.platformDescription")}
            <a href="http://www.barzelbank.com">www.barzelbank.com</a>,{" "}
            {translate("terms.platformInvestments")}
          </Paragraph>
          <Paragraph>
            <strong>{translate("terms.tokens")}</strong>{" "}
            {translate("terms.tokensDescription")}
          </Paragraph>
          <Paragraph>
            <strong>{translate("terms.users")}</strong>
            <ul>
              <li>
                <strong>{translate("terms.investor")}</strong>{" "}
                {translate("terms.investorDescription")}
              </li>
              <li>
                <strong>{translate("terms.issuer")}</strong>{" "}
                {translate("terms.issuerDescription")}
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            <strong>{translate("terms.asset")}</strong>{" "}
            {translate("terms.assetDescription")}
          </Paragraph>

          <SectionTitle>{translate("terms.section2")}</SectionTitle>
          <Paragraph>{translate("terms.objective")}</Paragraph>
          <Paragraph>
            {translate("terms.beforeInvesting")}
            <ul>
              <li>{translate("terms.riskTerm")}</li>
              <li>{translate("terms.guide")}</li>
            </ul>
          </Paragraph>

          <SectionTitle>{translate("terms.section3")}</SectionTitle>
          <Paragraph>
            {translate("terms.registrationRequired")}
            <ul>
              <li>{translate("terms.adult")}</li>
              <li>{translate("terms.provideDocuments")}</li>
              <li>{translate("terms.kyc")}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <strong>{translate("terms.userResponsibilities")}</strong>
            <ul>
              <li>{translate("terms.protectCredentials")}</li>
              <li>{translate("terms.updateInfo")}</li>
              <li>{translate("terms.respectTerms")}</li>
            </ul>
          </Paragraph>
          <Paragraph>{translate("terms.rightToRefuse")}</Paragraph>

          <SectionTitle>{translate("terms.section4")}</SectionTitle>
          <Paragraph>
            {translate("terms.investorAccess")}
            <ul>
              <li>{translate("terms.dashboard")}</li>
              <li>{translate("terms.history")}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            {translate("terms.issuerAccess")}
            <ul>
              <li>{translate("terms.manageProjects")}</li>
              <li>{translate("terms.monitorTransactions")}</li>
              <li>{translate("terms.provideFAQ")}</li>
            </ul>
          </Paragraph>

          <SectionTitle>{translate("terms.section5")}</SectionTitle>
          <Paragraph>
            <ul>
              <li>{translate("terms.freeRegistration")}</li>
              <li>{translate("terms.issuerFees")}</li>
              <li>{translate("terms.capitalGains")}</li>
            </ul>
          </Paragraph>

          <SectionTitle>{translate("terms.section6")}</SectionTitle>
          <Paragraph>
            {translate("terms.noGuarantees")}
            <ul>
              <li>{translate("terms.financialSuccess")}</li>
              <li>{translate("terms.noFailures")}</li>
            </ul>
          </Paragraph>
          <Paragraph>{translate("terms.userLiability")}</Paragraph>

          <SectionTitle>{translate("terms.section7")}</SectionTitle>
          <Paragraph>{translate("terms.ipNotice")}</Paragraph>

          <SectionTitle>{translate("terms.section8")}</SectionTitle>
          <Paragraph>{translate("terms.unilateralChanges")}</Paragraph>
          <Paragraph>{translate("terms.accountClosure")}</Paragraph>

          <SectionTitle>{translate("terms.section9")}</SectionTitle>
          <Paragraph>{translate("terms.jurisdiction")}</Paragraph>

          <SectionTitle>{translate("terms.contact")}</SectionTitle>
          <Paragraph>{translate("terms.contactInfo")}</Paragraph>

          <Paragraph>© {translate("terms.copyright")}</Paragraph>
        </TermsContainer>
      </div>
      <div className="cs_height_100" />
    </>
  );
}
