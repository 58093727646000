import React, { useState } from "react";
import Spacing from "../Spacing";
import { pageTitle } from "../../helpers/PageTitle";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import LoaderComponent from "../../components/Loader/Loader";
import { useTranslation } from "../../hooks/useTranslation";

export const ContactInfoWrapper = styled.div`
  padding: 20px;
`;

export const LocationBox = styled.div`
  padding: 20px 20px 20px 0px;
  height: 100%;

  h3 {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50px;
    font-size: 20px;
  }
`;

export const TextRegular = styled.p`
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.8);
`;

export const FooterText = styled.div`
  margin: 40px 0;
  text-align: center;
`;

export const ContactForm = styled.form`
  padding: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;

  &:focus {
    outline: none;
    border-color: #bf9a5d;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  min-height: 150px;

  &:focus {
    outline: none;
    border-color: #bf9a5d;
  }
`;

export const SubmitButton = styled.button`
  background: #bf9a5d;
  color: #fff;
  padding: 12px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }
`;

export default function ContactPage() {
  pageTitle("Contato");
  const { translate, language } = useTranslation();

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    assunto: "",
    mensagem: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.nome ||
      !formData.email ||
      !formData.assunto ||
      !formData.mensagem
    ) {
      toast.error(translate("contactPage.fillAllFields"));
      return;
    }

    const submitButton = e.target.querySelector('button[type="submit"]');
    submitButton.disabled = true;
    setIsLoading(true);

    try {
      if (formData?.mensagem?.length > 144) {
        toast.error(translate("contactPage.messageLimit"));
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        toast.error(translate("contactPage.invalidEmail"));
        return;
      }

      const dataSend = {
        name: formData.nome,
        email: formData.email,
        subject: formData?.assunto,
        message: formData?.mensagem,
      };

      const response = await fetch(
        "https://contactform-qj6yfdqbiq-uc.a.run.app/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );

      if (response?.ok) {
        toast.success(translate("contactPage.successMessage"));
      } else {
        toast.error(translate("contactPage.failureMessage"));
      }

      setFormData({
        nome: "",
        email: "",
        assunto: "",
        mensagem: "",
      });
    } catch (error) {
      toast.error(translate("contactPage.failureMessage"));
    } finally {
      setIsLoading(false);
      submitButton.disabled = false;
    }
  };

  return (
    <>
      <Spacing lg="70" md="40" />
      <Spacing lg="70" md="40" />
      <div className="container">
        <div className="row align-items-start">
          {/* Lado esquerdo - Informações de Contato */}
          <div className="col-lg-6">
            <ContactInfoWrapper>
              <h1 className="cs_hero_title cs_fs_68">
                {language === "en" ? (
                  <>
                    <span style={{ color: "#BF9A5D" }}>
                      {translate("contactPage.title")}
                    </span>
                    {translate("contactPage.contactInfo")}{" "}
                  </>
                ) : (
                  <>
                    {translate("contactPage.contactInfo")}
                    <span style={{ color: "#BF9A5D" }}>
                      {translate("contactPage.title")}
                    </span>{" "}
                  </>
                )}
              </h1>
              <div className="row">
                {/* Contato */}
                <div className="col-md-6 mb-4">
                  <LocationBox>
                    <h3 className="cs_fs_24 cs_semibold mb-3">
                      {translate("contactPage.contactInfo")}
                    </h3>
                    <TextRegular>
                      {translate("contactPage.website")}
                    </TextRegular>
                    <TextRegular>{translate("contactPage.phone")}</TextRegular>
                    <TextRegular>{translate("contactPage.email")}</TextRegular>
                  </LocationBox>
                </div>

                {/* Maceió */}
                <div className="col-md-6 mb-4">
                  <LocationBox>
                    <h3 className="cs_fs_24 cs_semibold mb-3">Maceió</h3>
                    <TextRegular>R. C José Pontes de</TextRegular>
                    <TextRegular>Magalhães Jatiúca,</TextRegular>
                    <TextRegular>Maceió - Alagoas</TextRegular>
                  </LocationBox>
                </div>

                {/* Lisboa */}
                <div className="col-md-6 mb-4">
                  <LocationBox>
                    <h3 className="cs_fs_24 cs_semibold mb-3">Lisboa</h3>
                    <TextRegular>R. Alexandre</TextRegular>
                    <TextRegular>Herculano 50</TextRegular>
                    <TextRegular>Lisboa, Portugal</TextRegular>
                  </LocationBox>
                </div>

                {/* Siga-nos */}
                <div className="col-md-6 mb-4">
                  <LocationBox>
                    <h3 className="cs_fs_24 cs_semibold mb-3">Siga-nos</h3>
                    <TextRegular>@Barzel.Capital</TextRegular>
                  </LocationBox>
                </div>
              </div>
            </ContactInfoWrapper>
          </div>

          {/* Lado direito - Formulário de Contato */}
          <div className="col-lg-6">
            <ContactForm onSubmit={handleSubmit}>
              <h2 className="cs_fs_40 mb-4">
                {translate("contactPage.sendMessage")}
              </h2>

              <FormGroup>
                <Input
                  type="text"
                  name="nome"
                  placeholder={translate("contactPage.namePlaceholder")}
                  value={formData.nome}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  placeholder={translate("contactPage.emailPlaceholder")}
                  value={formData.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title={translate("contactPage.invalidEmail")}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="assunto"
                  placeholder={translate("contactPage.subjectPlaceholder")}
                  value={formData.assunto}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <TextArea
                  name="mensagem"
                  placeholder={translate("contactPage.messagePlaceholder")}
                  value={formData.mensagem}
                  onChange={handleChange}
                  maxLength={144}
                />
              </FormGroup>

              <SubmitButton type="submit" disabled={isLoading}>
                {isLoading ? (
                  <LoaderComponent
                    color="#BF9A5D"
                    borderThickness={3}
                    size={26}
                  />
                ) : (
                  translate("contactPage.submitButton")
                )}
              </SubmitButton>
            </ContactForm>
          </div>
        </div>
      </div>
      <div className="cs_height_100" />
    </>
  );
}
