import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTranslation } from "../../../hooks/useTranslation";
import LoaderComponent from "../../Loader/Loader";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../Spacing";
import { getUserBalance } from "../investments/GetUserBalance";

const CryptoAdmin = ({ darkMode }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        console.log("Saldo do usuário:", userBalance);
      }
    };

    fetchBalance();
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingActions, setLoadingActions] = useState({});

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error(translate("cryptoAdmin.loadingTransactions"));
          return;
        }

        const response = await fetch(
          `https://gettradecryptolist-qj6yfdqbiq-uc.a.run.app/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log("Resposta da API:", data);

        const pendingTrades = data.transactions.filter(
          (trade) => trade.status === "pending"
        );
        setDeposits(pendingTrades);
        console.log("Trades pendentes:", pendingTrades);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const formatAmount = (amount, type) => {
    if (type === "BTC") {
      const btcAmount = amount / 100000000;
      return `₿${btcAmount.toFixed(8)}`;
    } else {
      const fiatAmount = amount / 100;
      return `R$${fiatAmount.toFixed(2)}`;
    }
  };

  const handleConfirmTransaction = async (tradeId, action) => {
    setLoadingActions((prev) => ({ ...prev, [`${action}-${tradeId}`]: true }));
    try {
      const token = localStorage.getItem("userToken");
      console.log(tradeId, action);

      if (!token) {
        toast.error(translate(`cryptoAdmin.${action}Error`));
        return;
      }

      const response = await fetch(
        `https://adminconfirmcryptopurchase-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            txId: tradeId,
            action: action === "confirm" ? "approve" : "reject",
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || translate(`cryptoAdmin.${action}Success`));
        setDeposits((prevTrades) =>
          prevTrades.filter((trade) => trade.id !== tradeId)
        );
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || translate(`cryptoAdmin.${action}Error`));
      }
    } catch (error) {
      console.error(`Erro ao ${action} a transação:`, error);
      toast.error(translate(`cryptoAdmin.${action}Error`));
    } finally {
      setLoadingActions((prev) => ({
        ...prev,
        [`${action}-${tradeId}`]: false,
      }));
    }
  };

  const handleConfirmPurchase = (tradeId) => handleConfirmTransaction(tradeId, "confirm");
  const handleRejectPurchase = (tradeId) => handleConfirmTransaction(tradeId, "reject");

  const handleConfirmSale = (tradeId) => handleConfirmTransaction(tradeId, "confirm");
  const handleRejectSale = (tradeId) => handleConfirmTransaction(tradeId, "reject");

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("cryptoAdmin.title")}
        subTitle={translate("cryptoAdmin.subtitle")}
        variant="text-left"
        href="/deposit-admin"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.email")}
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.cpf")}
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.date")}
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.amount")}
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.type")}
                    </th>
                    {/* <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.receipt")}
                    </th> */}
                    <th style={{ width: "20%", textAlign: "center" }}>
                      {translate("cryptoAdmin.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        rowSpan="10"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <LoaderComponent />
                      </td>
                    </tr>
                  ) : (
                    deposits.map((trade, index) => (
                      <tr
                        key={trade.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {trade.userEmail}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {trade.userCpf || "N/A"}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatDate(trade.timestamp)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatAmount(trade.amount, trade.type)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {trade.type === "BTC_PURCHASE"
                            ? translate("cryptoAdmin.buy")
                            : translate("cryptoAdmin.sell")}
                        </td>
                        {/* <td style={{ width: "20%", textAlign: "center" }}>
                          <button
                            onClick={() => handleDownload(trade.receipt)}
                            disabled={trade.receipt === "none"}
                            style={{
                              background:
                                trade.receipt === "none"
                                  ? "#a9a9a9"
                                  : "#007bff",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              cursor:
                                trade.receipt === "none"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            {trade.receipt === "none"
                              ? translate("cryptoAdmin.noReceipt")
                              : translate("cryptoAdmin.downloadReceipt")}
                          </button>
                        </td> */}
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {trade.type === "BTC_PURCHASE" ? (
                            <>
                              <button
                                onClick={() => handleConfirmPurchase(trade.id)}
                                disabled={loadingActions[`confirm-${trade.id}`]}
                                style={{
                                  background: loadingActions[
                                    `confirm-${trade.id}`
                                  ]
                                    ? "#a9a9a9"
                                    : "#11C160",
                                  padding: "10px 20px",
                                  border: "none",
                                  width: "100%",
                                  display: "block",
                                  color: "white",
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                  cursor: loadingActions[`confirm-${trade.id}`]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                {loadingActions[`confirm-${trade.id}`] ? (
                                  <div
                                    style={{
                                      marginInline: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LoaderComponent
                                      size={20}
                                      borderThickness={2}
                                      color="#FFF"
                                    />
                                  </div>
                                ) : (
                                  translate("crypto.Admin.button.purchase.confirm")
                                )}
                              </button>
                              <button
                                onClick={() => handleRejectPurchase(trade.id)}
                                disabled={loadingActions[`reject-${trade.id}`]}
                                style={{
                                  background: loadingActions[
                                    `reject-${trade.id}`
                                  ]
                                    ? "#a9a9a9"
                                    : "#E83238",
                                  padding: "10px 20px",
                                  border: "none",
                                  width: "100%",
                                  display: "block",
                                  color: "white",
                                  fontWeight: "bold",
                                  cursor: loadingActions[`reject-${trade.id}`]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                {loadingActions[`reject-${trade.id}`] ? (
                                  <div
                                    style={{
                                      marginInline: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LoaderComponent
                                      size={20}
                                      borderThickness={2}
                                      color="#FFF"
                                    />
                                  </div>
                                ) : (
                                  translate("crypto.Admin.button.purchase.reject")
                                )}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => handleConfirmSale(trade.id)}
                                disabled={
                                  loadingActions[`confirm-sale-${trade.id}`]
                                }
                                style={{
                                  background: loadingActions[
                                    `confirm-sale-${trade.id}`
                                  ]
                                    ? "#a9a9a9"
                                    : "#11C160",
                                  padding: "10px 20px",
                                  border: "none",
                                  width: "100%",
                                  display: "block",
                                  color: "white",
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                  cursor: loadingActions[
                                    `confirm-sale-${trade.id}`
                                  ]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                {loadingActions[`confirm-sale-${trade.id}`] ? (
                                  <div
                                    style={{
                                      marginInline: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LoaderComponent
                                      size={20}
                                      borderThickness={2}
                                      color="#FFF"
                                    />
                                  </div>
                                ) : (
                                  translate("crypto.Admin.button.sale.confirm")
                                )}
                              </button>
                              <button
                                onClick={() => handleRejectSale(trade.id)}
                                disabled={
                                  loadingActions[`reject-sale-${trade.id}`]
                                }
                                style={{
                                  background: loadingActions[
                                    `reject-sale-${trade.id}`
                                  ]
                                    ? "#a9a9a9"
                                    : "#E83238",
                                  padding: "10px 20px",
                                  border: "none",
                                  width: "100%",
                                  display: "block",
                                  color: "white",
                                  fontWeight: "bold",
                                  cursor: loadingActions[
                                    `reject-sale-${trade.id}`
                                  ]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                {loadingActions[`reject-sale-${trade.id}`] ? (
                                  <div
                                    style={{
                                      marginInline: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LoaderComponent
                                      size={20}
                                      borderThickness={2}
                                      color="#FFF"
                                    />
                                  </div>
                                ) : (
                                  translate("crypto.Admin.button.sale.reject")
                                )}
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("cryptoAdmin.previous")}
              </button>
              <span>
                {translate("cryptoAdmin.page")} {currentPage} {translate("of")}{" "}
                {totalPages}
              </span>
              <button
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                {translate("cryptoAdmin.next")}
              </button>
            </div>

            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoAdmin;
